import { useState } from "react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { WDID } from "mock/WDID";

import store from "ui/redux/store";
import { Title } from "ui/base/Title";
import { Col } from "ui/blocks/portal/PortalView/PortalViewStyled";
import { CheckBox } from "ui/base/CheckBox";

import { RequirementsWrapper, Text } from "./StormwaterMonitoringColStyled";

const REQUEST = gql`
  query MyQuery($report_year: String!, $wdid: String!) {
    portal_qry_sampling_requirements(where: { report_year: { _eq: $report_year }, wdid: { _eq: $wdid } }) {
      wdid
      sampling_period
      sampled_event_count
      required_sample
      report_year
    }
  }
`;

type itemType = {
  wdid: string;
  sampling_period: string;
  sampled_event_count: string;
  required_sample: string;
};

const StormwaterMonitoringCol = (props: any) => {
  const params = props;
  const [newWDID, setNewWDID] = useState(WDID);
  const date = new Date();
  const reportYear =
    date.getMonth() + 1 > 6
      ? `${date.getFullYear()}-${date.getFullYear() + 1}`
      : `${date.getFullYear() - 1}-${date.getFullYear()}`;
  const { loading, error, data } = useQuery(REQUEST, { variables: { report_year: reportYear, wdid: newWDID } });
  store.subscribe(() => {
    setNewWDID(store.getState().wdid);
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <Col>
      <Title size="h5" position="left">
        Stormwater Monitoring Requirements
      </Title>
      <Text>
        (The facility shall complete the required number of stormwater sampling events by end of each reporting year
        according to the requirements below. )
      </Text>
      <RequirementsWrapper background={params.background}>
        <Title size="biggest" uppercase={false} position="left" color="blue">
          Sampling Requirements
        </Title>
        {data.portal_qry_sampling_requirements.map(
          ({ required_sample, sampled_event_count, sampling_period }: itemType) => {
            if (required_sample !== "N/A") {
              return (
                <CheckBox key={`${required_sample}${Math.random()}`} value={sampled_event_count >= required_sample}>
                  Collect stormwater sample from
                  <a>{required_sample} QSE</a>
                  from {sampling_period}
                </CheckBox>
              );
            } else return null;
          }
        )}
      </RequirementsWrapper>
    </Col>
  );
};

export default StormwaterMonitoringCol;
