import styled from "styled-components";
import { themeColor } from "../../theme";

export const ModalWarningContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const ModalWarningForm = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  gap: 20px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 40px 80px;
  flex-direction: column;
  justify-content: space-between;
  background: ${themeColor("white")};
  border: 1px solid #c2c2c2;
  font-size: 24px;
`;

export const ModalWarningH1Text = styled.p`
  color: ${themeColor("blue")};
  font-size: 24px;
  font-weight: 700;
`;

export const ModalWarningButton = styled.button<{ buttonType: "agree" | "disagree" }>`
  display: flex;
  font-size: 16px;
  text-transform: capitalize;
  height: 46px;
  width: 138px;
  border: none;
  cursor: pointer;
  text-align: center;
  padding: 5px 27px;
  border-radius: 10px;
  background: ${({ buttonType }) => (buttonType === "disagree" ? "#C62300" : themeColor("blue"))}};
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
