import { useEffect, useState } from "react";

import api from "api";
import store from "ui/redux/store";

import { Table } from "ui/base/Table";

import { MonitotingViewTable, Text } from "ui/blocks/monitoring/MonitoringView/MonitoringViewStyled";
import { WDID } from "mock/WDID";
import { Button } from "ui/base/Button";
import {
  SelectOptions,
  Wrapper,
  Btn,
  OptionsList,
  Option,
  Container,
} from "ui/blocks/WaterQuality/WaterQualityView/WaterQualityViewStyled";
import { resolveObjMapThunk } from "graphql";
import { DownArrow } from "ui/icons";

type Data = {
  id: number;
  reporting_year: string;
  sample_date: string;
  status: string;
  wdid: string;
  date_complete?: string;
};

const SampleObservationTable = () => {
  const [sampleFacilityData, setSampleFacilityData] = useState<Data[]>([]);
  const [selectedYearRange, setSelectedYearRange] = useState<string>("All");
  const [filteredRows, setFilteredRows] = useState<Data[]>([]);
  const [newWDID, setNewWDID] = useState<string>(WDID);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setNewWDID(store.getState().wdid);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.get("event_observations");
        let data = result.data as Data[];
        const updatedData = data.map((item) => {
          if (item.sample_date) {
            return {
              ...item,
              status: `Completed ${new Date(item.sample_date).toLocaleDateString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              })}`,
              date_complete: new Date().toISOString(),
            };
          }
          return item;
        });

        setSampleFacilityData(updatedData);
        setFilteredRows(updatedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, []);

  const uniqueYearRanges = Array.from(
    new Set(
      sampleFacilityData.map(
        ({ reporting_year }) => `${reporting_year.split("-")[0]}-${parseInt(reporting_year.split("-")[0]) + 1}`
      )
    )
  ).sort();

  const [selectedOption, setSelectedOption] = useState(uniqueYearRanges.indexOf(selectedYearRange));

  useEffect(() => {
    let filtered = sampleFacilityData;
    if (selectedYearRange !== "All") {
      const [startYear, endYear] = selectedYearRange.split("-").map(Number);
      filtered = filtered.filter(({ reporting_year }) => {
        const parsedYear = parseInt(reporting_year.split("-")[0], 10);
        return parsedYear >= startYear && parsedYear <= endYear;
      });
    }
    filtered = filtered.filter(({ wdid }) => wdid === newWDID);
    setFilteredRows(filtered);
  }, [selectedYearRange, sampleFacilityData, newWDID]);

  const toggleOptions = () => setIsOptionsOpen(!isOptionsOpen);

  const setSelectedThenCloseDropdown = (index: number) => {
    setSelectedOption(index);
    setSelectedYearRange(uniqueYearRanges[index]);
    setIsOptionsOpen(false);
  };

  return (
    <MonitotingViewTable style={{ position: "relative" }}>
      <Text>Sample Event Visual Observation Forms</Text>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "baseline",
          gap: "10px",
          flexWrap: "wrap",
          marginBottom: "20px",
        }}
      >
        <SelectOptions>
          <Wrapper>
            <Container>
              <Btn
                type="button"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
                onClick={toggleOptions}
              >
                <span>{selectedOption === -1 ? "Select year range" : `${uniqueYearRanges[selectedOption]}`}</span>
                <div
                  style={{
                    width: "20px",
                    position: "absolute",
                    right: "5px",
                    top: "9px",
                    transform: `${isOptionsOpen ? "rotate(180deg)" : "rotate(0deg)"}`,
                  }}
                >
                  <DownArrow />
                </div>
              </Btn>
              <OptionsList isOpen={isOptionsOpen}>
                {uniqueYearRanges.map((option, index) => (
                  <Option
                    key={option}
                    id={option}
                    aria-selected={selectedOption === index}
                    onClick={() => setSelectedThenCloseDropdown(index)}
                  >
                    {option}
                  </Option>
                ))}
              </OptionsList>
            </Container>
          </Wrapper>
        </SelectOptions>
        <Button color="white" background="darkBlue">
          Download blank form
        </Button>

        <Button color="white">
          <a href={`/sample-facility-form?&wdid=${newWDID}`}>Add</a>
        </Button>
      </div>
      <Table
        header={
          <tr>
            <th>Reporting Year</th>
            <th>Sample Date</th>
            <th>Status</th>
            <th colSpan={2}>Form</th>
          </tr>
        }
      >
        {filteredRows.map((row) => (
          <tr key={row.id}>
            <td style={{ textAlign: "center" }}>
              <strong>{row.reporting_year}</strong>
            </td>
            <td style={{ textAlign: "center" }}>
              <strong>
                {new Date(row.sample_date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </strong>
            </td>
            <td style={{ textAlign: "center" }}>{row.status}</td>
            <td style={{ textAlign: "center" }}>
              <a href={`/sample-facility-form?id=${row.id}&mode=view&wdid=${row.wdid}`}>View</a>
            </td>
            <td style={{ textAlign: "center" }}>
              <a href={`/sample-facility-form?id=${row.id}&mode=edit&wdid=${row.wdid}`}>Edit</a>
            </td>
          </tr>
        ))}
      </Table>
    </MonitotingViewTable>
  );
};

export default SampleObservationTable;
