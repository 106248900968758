import api from "api";
import React, { useEffect, useState, useCallback } from "react";

import { WDID } from "mock/WDID";

import store from "ui/redux/store";
import { Table } from "ui/base/Table";
import { MonitotingViewTable, Text } from "ui/blocks/monitoring/MonitoringView/MonitoringViewStyled";

import {
  SelectOptions,
  Wrapper,
  Btn,
  Container,
  OptionsList,
  Option,
} from "ui/blocks/WaterQuality/WaterQualityView/WaterQualityViewStyled";
import { Button } from "ui/base/Button";
import { DownArrow } from "ui/icons";

interface TableRow {
  id: number;
  month: string;
  year: string;
  status: string;
  date_time: string;
  wdid: string;
}

interface MonthData {
  month: string;
  year: string;
  status: string;
  date_time?: string;
  id?: number;
  wdid?: string;
}

const MontlyObservationTable: React.FC = () => {
  const [rows, setRows] = useState<TableRow[]>([]);
  const [selectedYearRange, setSelectedYearRange] = useState<string>("2022-2023");
  const [filteredMonths, setFilteredMonths] = useState<MonthData[]>([]);
  const [newWDID, setNewWDID] = useState<string>(WDID);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const hardcodedYearRanges = ["2020-2021", "2021-2022", "2022-2023", "2023-2024", "2024-2025", "2025-2026"];

  const generateMonthsForYearRange = (yearRange: string): MonthData[] => {
    const [startYear, endYear] = yearRange.split("-").map(Number);
    const monthsOrder = [
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
    ];

    const months: MonthData[] = monthsOrder.map((month) => {
      let year = startYear;
      if (["January", "February", "March", "April", "May", "June"].includes(month)) {
        year = endYear;
      }
      return {
        month,
        year: year.toString(),
        status: "Future",
      };
    });

    return months;
  };

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(hardcodedYearRanges.indexOf("2022-2023"));

  const toggleOptions = () => {
    setIsOptionsOpen((prev) => !prev);
  };

  const setSelectedThenCloseDropdown = (index: number) => {
    setSelectedOption(index);
    setSelectedYearRange(hardcodedYearRanges[index]);
    setIsOptionsOpen(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await api.get("facility_monitoring");
      setRows(result.data);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setNewWDID(store.getState().wdid);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const filterData = () => {
      const currentDate = new Date();
      const months = generateMonthsForYearRange(selectedYearRange);
      const dataMap = rows.reduce((acc, row) => {
        const key = `${row.month.toLowerCase()}-${row.year}-${row.wdid}`;
        acc[key] = row;
        return acc;
      }, {} as Record<string, TableRow>);

      const mappedMonths = months.map((monthData) => {
        const key = `${monthData.month.toLowerCase()}-${monthData.year}-${newWDID}`;
        const matchingRow = dataMap[key];
        let status = "Future";

        if (matchingRow && matchingRow.status) {
          status = matchingRow.status;
        } else {
          const monthIndex = new Date(`${monthData.year}-${monthData.month}-01`).getMonth();
          const year = parseInt(monthData.year, 10);
          const monthDate = new Date(year, monthIndex, 1);

          if (monthDate <= currentDate) {
            status = "Not Complete";
          }
        }
        return {
          month: monthData.month,
          year: monthData.year,
          status,
          date_time: matchingRow ? matchingRow.date_time : undefined,
          id: matchingRow ? matchingRow.id : undefined,
          wdid: matchingRow ? matchingRow.wdid : undefined,
        };
      });

      setFilteredMonths(mappedMonths);
    };

    filterData();
  }, [selectedYearRange, rows, newWDID]);

  return (
    <MonitotingViewTable style={{ position: "relative" }}>
      <Text>Monthly Visual Observation Form</Text>
      <div
        style={{
          flexWrap: "wrap",
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "baseline",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <SelectOptions>
          <Wrapper>
            <Container>
              <Btn
                type="button"
                style={{ display: "flex", flexDirection: "row", position: "relative" }}
                onClick={toggleOptions}
              >
                <span style={{ width: "100%" }}>{`${hardcodedYearRanges[selectedOption]}`}</span>
                <div
                  style={{
                    width: "20px",
                    position: "absolute",
                    right: "5px",
                    top: "9px",
                    transform: `${isOptionsOpen ? "rotate(180deg)" : "rotate(0deg)"}`,
                  }}
                >
                  <DownArrow />
                </div>
              </Btn>
              <OptionsList isOpen={isOptionsOpen}>
                {hardcodedYearRanges.map((option, index) => (
                  <Option
                    key={option}
                    id={option}
                    aria-selected={selectedOption === index}
                    onClick={() => setSelectedThenCloseDropdown(index)}
                  >
                    {option}
                  </Option>
                ))}
              </OptionsList>
            </Container>
          </Wrapper>
        </SelectOptions>
        <Button color="white" background="darkBlue">
          Download blank form
        </Button>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div style={{ color: "red" }}>{error}</div>
      ) : (
        <Table
          header={
            <tr>
              <th>Month</th>
              <th>Year</th>
              <th>Status</th>
              <th colSpan={3}>Form</th>
            </tr>
          }
        >
          {filteredMonths.map((monthData) => (
            <tr key={`${monthData.month}-${monthData.year}-${monthData.id || "new"}`}>
              <td style={{ textAlign: "center" }}>
                <strong>{monthData.month}</strong>
              </td>
              <td style={{ textAlign: "center" }}>
                <strong>{monthData.year}</strong>
              </td>
              <td style={{ textAlign: "center" }}>{monthData.status}</td>
              <td style={{ textAlign: "center" }}>
                <a
                  style={{ opacity: !monthData.id ? 0.5 : 1 }}
                  href={
                    !monthData.id
                      ? "/monitoring"
                      : `/monthly-observation-form?id=${monthData.id}&mode=view&wdid=${monthData.wdid}`
                  }
                >
                  View
                </a>
              </td>
              <td style={{ textAlign: "center" }}>
                <a
                  href={
                    monthData.id
                      ? `/monthly-observation-form?id=${monthData.id}&mode=edit&wdid=${monthData.wdid}`
                      : `/monthly-observation-form?month=${monthData.month}&year=${monthData.year}&wdid=${newWDID}`
                  }
                >
                  Start/Edit
                </a>
              </td>
              <td style={{ textAlign: "center" }}>
                <a
                  href={
                    monthData.id
                      ? `/monthly-observation-form-update?id=${monthData.id}&wdid=${newWDID}`
                      : `/monthly-observation-form-update?month=${monthData.month}&year=${monthData.year}&wdid=${newWDID}`
                  }
                >
                  Upload
                </a>
              </td>
            </tr>
          ))}
        </Table>
      )}
    </MonitotingViewTable>
  );
};

export default MontlyObservationTable;
