import React, { useState, ChangeEvent, FormEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToaster } from "context/TosterContext";

import api from "api";
import { updateFacilityMonitoring, createFacilityMonitoring } from "helpers/facilityMonitoring";

import { Button } from "ui/base/Button";
import { UploadFile } from "ui/icons/UploadFile";
import { Col } from "ui/blocks/WaterQuality/WaterQualityView/Col";
import { FormRow, Label, Text, SubmitBtn } from "ui/blocks/ContactUsView/ContactUsViewStyled";


interface RootState {
  user: {
    id: number;
  } | null;
}

const SampleObservationFormUpdate: React.FC = () => {
  const { addToast } = useToaster();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  const idParam = queryParams.get("id");
  const id = idParam ? Number(idParam) : null;
  const wdid = queryParams.get("wdid");

  const user = useSelector((state: RootState) => state.user);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedFile) return;

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("input", selectedFile);

      const response = await api.addOne(formData);
      const fileUrl = response?.data?.url;

      if (fileUrl) {
        if (id !== null && id !== null) {
          await updateFacilityMonitoring(id, "event_observations", { url: fileUrl });
        } else {
          if (!user) {
            throw new Error("No user found");
          }
          await createFacilityMonitoring("event_observations", {
            user_id: user.id,
            wdid: wdid,
            url: fileUrl,
            reporting_year: new Date().getFullYear(),
            sample_date: new Date().toISOString().split("T")[0],
            status: `Completed ${new Date().toLocaleDateString()}`,
          });
        }
        addToast("Successfully uploaded!", "success");
        setTimeout(() => {
          navigate("/monitoring");
        }, 2000);
      } else {
        throw new Error("Can't get file url");
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    } else {
      setSelectedFile(null);
      setFileName("");
    }
  };

  return (
    <div>
      <div className="header" style={{ display: "flex", justifyContent: "flex-end", margin: "40px" }}>
        <Button color="white" background="darkBlue" onClick={() => navigate("/monitoring")}>
          Back
        </Button>
      </div>

      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          border: "5px dashed #183765",
          maxWidth: "800px",
          padding: "60px 20px",
          margin: "40px auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Col>
          <div style={{ width: "100px", marginLeft: "15px", opacity: "0.5" }}>
            <UploadFile />
          </div>
        </Col>
        <FormRow
          style={{
            width: "100%",
            display: "flex",
            marginTop: "-5px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Label htmlFor="file-input" style={{ cursor: "pointer", width: "fit-content" }}>
            <span style={{ marginLeft: "5px", whiteSpace: "nowrap" }}>Drop Files Here to Upload</span>
            <input
              onChange={handleFileChange}
              name="link"
              type="file"
              id="file-input"
              style={{ display: "none" }}
              accept=".jpg,.jpeg,.png,.pdf"
            />
          </Label>
          {fileName && <Text>{fileName}</Text>}
        </FormRow>
        {selectedFile && (
          <FormRow>
            <SubmitBtn type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Sending..." : id !== null ? "Upload" : "Send"}
            </SubmitBtn>
          </FormRow>
        )}
      </form>
    </div>
  );
};

export default SampleObservationFormUpdate;
