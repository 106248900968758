import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useToaster } from "context/TosterContext";
import ReactToPrint from "react-to-print";

import {
  createFacilityMonitoring,
  deleteFacilityMonitoring,
  updateFacilityMonitoring,
} from "helpers/facilityMonitoring";

import { Button } from "ui/base/Button";
import {
  FormCell,
  FormHeader,
  FormRow,
  FormTable,
  FormLabel,
  FormInput,
  FormSelectLabel,
} from "./MonthlyVisualObservationStyled";

import { AnnualFacilityDataProps, initialAnnualFacilityData, AnnualFacilityActivitiesRowsProps } from "../types";

import { ModalWarning } from "ui/base/ModalWarning";
import api from "api";

interface RootState {
  user: {
    id: number;
  } | null;
}

interface RadioGroupProps {
  name: string;
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  required?: boolean;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ name, value, onChange, disabled = false, required = false }) => (
  <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 15 }}>
    <FormSelectLabel>
      <input
        type="radio"
        name={name}
        value="true"
        checked={value === true}
        onChange={() => onChange(true)}
        disabled={disabled}
        required={required}
      />
      Yes
    </FormSelectLabel>
    <p>or</p>
    <FormSelectLabel>
      <input
        type="radio"
        name={name}
        value="false"
        checked={value === false}
        onChange={() => onChange(false)}
        disabled={disabled}
        required={required}
      />
      No
    </FormSelectLabel>
  </div>
);

interface AnnualFacilityFormProps {
  isReadOnly?: boolean;
  wdid: string | null;
  formData: AnnualFacilityDataProps | null;
}

const AnnualFacilityForm: React.FC<AnnualFacilityFormProps> = ({ isReadOnly = false, wdid, formData }) => {
  const { addToast } = useToaster();
  const componentRef = useRef<HTMLDivElement>(null);

  const user = useSelector((state: RootState) => state.user);

  const [annualFacilityData, setAnnualFacilityData] = useState<AnnualFacilityDataProps>(initialAnnualFacilityData);
  const [annualFacilityActivitiesRows, setAnnualFacilityActivitiesRows] = useState<AnnualFacilityActivitiesRowsProps[]>(
    [
      {
        id: undefined,
        annual_facility_id: undefined,
        activity_area: "",
        bmp_effectiveness: undefined,
        bmp_good_condition: undefined,
        corrective_action: "",
      },
    ]
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (formData) {
      setAnnualFacilityData(formData);
      if (formData.id) {
        api.get(`annual_facility_activities?annual_facility_id=eq.${formData.id}`).then((res: any) => {
          if (res.data?.length > 0) {
            setAnnualFacilityActivitiesRows(
              res.data.map((row: any) => ({
                ...row,
                bmp_effectiveness: row.bmp_effectiveness !== undefined ? row.bmp_effectiveness : undefined,
                bmp_good_condition: row.bmp_good_condition !== undefined ? row.bmp_good_condition : undefined,
              }))
            );
          }
        });
      }
    } else {
      setAnnualFacilityData(initialAnnualFacilityData);
      setAnnualFacilityActivitiesRows([
        {
          id: undefined,
          annual_facility_id: undefined,
          activity_area: "",
          bmp_effectiveness: undefined,
          bmp_good_condition: undefined,
          corrective_action: "",
        },
      ]);
    }
  }, [formData]);

  const reactToPrintContent = useCallback(() => componentRef.current, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const { name, value, type } = e.target;
      setAnnualFacilityData((prev) => ({
        ...prev,
        [name]: type === "radio" ? (value === "true" ? true : false) : value,
      }));
    },
    []
  );

  const handleInputChange = useCallback(
    (index: number, field: keyof AnnualFacilityActivitiesRowsProps, value: string | boolean) => {
      setAnnualFacilityActivitiesRows((prevRows) =>
        prevRows.map((row, i) => (i === index ? { ...row, [field]: value } : row))
      );
    },
    []
  );

  const handleAddRow = useCallback(() => {
    setAnnualFacilityActivitiesRows((prevRows) => [
      ...prevRows,
      {
        id: undefined,
        annual_facility_id: annualFacilityData.id,
        activity_area: "",
        bmp_effectiveness: undefined,
        bmp_good_condition: undefined,
        corrective_action: "",
      },
    ]);
  }, [annualFacilityData.id]);

  const handleRemoveRow = useCallback(
    async (index: number) => {
      const rowToDelete = annualFacilityActivitiesRows[index];
      if (rowToDelete.id) {
        try {
          await deleteFacilityMonitoring(rowToDelete.id, "annual_facility_activities");
          addToast("Row deleted from DB", "success");
        } catch (error) {
          console.error("Error deleting row from DB:", error);
          addToast("Error deleting row from DB", "error");
        }
      }

      setAnnualFacilityActivitiesRows((prevRows) => prevRows.filter((_, i) => i !== index));
    },
    [annualFacilityActivitiesRows, addToast]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        for (const row of annualFacilityActivitiesRows) {
          if (row.activity_area) {
            if (row.bmp_effectiveness === undefined || row.bmp_good_condition === undefined) {
              addToast("Please select Yes or No for all BMP fields.", "error");
              return;
            }
          }
        }

        let facilityId: number;

        if (!annualFacilityData.id) {
          const createdRows = await createFacilityMonitoring("annual_facility", {
            ...annualFacilityData,
            wdid: wdid,
            user_id: user?.id,
            status: "Future",
            reporting_year: new Date().getFullYear(),
            completed_by: annualFacilityData.date_time,
            facility: wdid,
          });

          if (Array.isArray(createdRows) && createdRows.length > 0) {
            facilityId = createdRows[0].id;
            setAnnualFacilityData((prev) => ({ ...prev, id: facilityId }));
          } else {
            throw new Error("Can't get new id.");
          }
        } else {
          await updateFacilityMonitoring(annualFacilityData.id, "annual_facility", {
            ...annualFacilityData,
          });
          facilityId = annualFacilityData.id;
        }

        await Promise.all(
          annualFacilityActivitiesRows.map(async (row) => {
            const payload = { ...row, annual_facility_id: facilityId };
            if (row.id) {
              return updateFacilityMonitoring(row.id, "annual_facility_activities", payload);
            } else {
              return createFacilityMonitoring("annual_facility_activities", payload);
            }
          })
        );

        addToast("Successfully updated!", "success");
        setTimeout(() => {
          window.location.href = "/monitoring";
        }, 2000);
      } catch (error: any) {
        addToast("Error updating data", "error");
        console.error("Error updating data:", error);
        console.error("Sent data", annualFacilityData);
        alert("Error updating data.");
      }
    },
    [annualFacilityData, annualFacilityActivitiesRows, wdid, user?.id, addToast]
  );

  const handleDeleteClick = useCallback(() => {
    if (!annualFacilityData.id) {
      addToast("No 'id' found. Could not delete record!", "error");
      return;
    }
    setIsOpen(true);
  }, [annualFacilityData.id, addToast]);

  const handleConfirmDelete = useCallback(
    async (confirmed: boolean) => {
      setIsOpen(false);
      if (confirmed && annualFacilityData.id) {
        try {
          await deleteFacilityMonitoring(annualFacilityData.id, "annual_facility_activities?annual_facility_id");
          await deleteFacilityMonitoring(annualFacilityData.id, "annual_facility");
          addToast("Form deleted successfully!", "success");
          setTimeout(() => {
            window.location.href = "/monitoring";
          }, 2000);
        } catch (error) {
          console.error("Error deleting data:", error);
          addToast("Error deleting form!", "error");
        }
      }
    },
    [annualFacilityData.id, addToast]
  );

  const handleCancelDelete = useCallback(() => {
    setIsOpen(false);
  }, []);

  const renderReadOnly = useCallback((value: string | null) => (value ? value : <em>N/A</em>), []);

  return (
    <div
      style={{
        display: "flex",
        width: "80%",
        margin: "40px auto",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <ModalWarning isOpen={isOpen} onConfirm={handleConfirmDelete} onCancel={handleCancelDelete} />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="white" type="button" background="darkBlue">
          <a href="/monitoring">Go back</a>
        </Button>
      </div>

      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
          flexWrap: "wrap",
        }}
      >
        <div style={{ justifyContent: "flex-end", width: "100%", display: "flex" }}>
          {isReadOnly ? (
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle={"Monitoring Form"}
              removeAfterPrint
              trigger={() => (
                <Button onClick={reactToPrintContent} type="button" color="white" background="blue">
                  Print completed form
                </Button>
              )}
            />
          ) : (
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {!annualFacilityData.url && (
                <Button color="white" type="submit" background="blue">
                  Save Form
                </Button>
              )}
              <Button color="white" type="button" onClick={handleDeleteClick}>
                Delete Form
              </Button>
            </div>
          )}
        </div>
        
        <div
          ref={componentRef}
          id="printable-section"
          style={{
            margin: "20px auto",
            pointerEvents: isReadOnly ? "none" : "auto",
            opacity: isReadOnly ? 0.9 : 1,
          }}
        >
          {annualFacilityData.url ? (
            <img src={annualFacilityData.url} style={{ width: "100%", height: "auto" }} alt="Annual-Facility" />
          ) : (
            <FormTable>
              <FormHeader>
                <FormCell style={{ display: "flex", justifyContent: "center" }}>General Information</FormCell>
              </FormHeader>
              <FormRow className="row">
                <FormCell
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <FormLabel>Facility:</FormLabel>
                  {isReadOnly ? renderReadOnly(annualFacilityData.wdid) : wdid}
                </FormCell>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Date and Time</FormLabel>
                  {isReadOnly ? (
                    <span>{renderReadOnly(annualFacilityData.date_time)}</span>
                  ) : (
                    <FormInput
                      type="datetime-local"
                      name="date_time"
                      value={annualFacilityData.date_time || ""}
                      onChange={handleChange}
                      required
                    />
                  )}
                </FormCell>
              </FormRow>
              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Name</FormLabel>
                  {isReadOnly ? (
                    <span>{renderReadOnly(annualFacilityData.name)}</span>
                  ) : (
                    <FormInput
                      type="text"
                      name="name"
                      value={annualFacilityData.name}
                      onChange={handleChange}
                      required
                    />
                  )}
                </FormCell>
                <FormCell>
                  <FormLabel>Title</FormLabel>
                  {isReadOnly ? (
                    <span>{renderReadOnly(annualFacilityData.title)}</span>
                  ) : (
                    <FormInput
                      type="text"
                      name="title"
                      value={annualFacilityData.title}
                      onChange={handleChange}
                      required
                    />
                  )}
                </FormCell>
              </FormRow>

              <FormHeader>
                <FormCell style={{ display: "flex", justifyContent: "center" }}>Part I. Document Review</FormCell>
              </FormHeader>
              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Were all 12 Monthly Visual Observations completed and maintained on-site?</FormLabel>
                  {isReadOnly ? (
                    <span>{annualFacilityData.visual_observations ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="visual_observations"
                      value={annualFacilityData.visual_observations}
                      onChange={(val) =>
                        setAnnualFacilityData((prev) => ({
                          ...prev,
                          visual_observations: val,
                        }))
                      }
                      required
                    />
                  )}
                </FormCell>
                {!annualFacilityData.visual_observations && (
                  <FormCell>
                    <FormLabel>Complete section below</FormLabel>
                    {isReadOnly ? (
                      <span>{annualFacilityData.visual_observations_notes || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="visual_observations_notes"
                        value={annualFacilityData.visual_observations_notes}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormCell>
                )}
              </FormRow>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>
                    Were samples collected and analyzed from the required number of qualifying storm events?
                  </FormLabel>
                  {isReadOnly ? (
                    <span>{annualFacilityData.qualifying_storm ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="qualifying_storm"
                      value={annualFacilityData.qualifying_storm}
                      onChange={(val) =>
                        setAnnualFacilityData((prev) => ({
                          ...prev,
                          qualifying_storm: val,
                        }))
                      }
                      required
                    />
                  )}
                </FormCell>
                {!annualFacilityData.qualifying_storm && (
                  <FormCell>
                    <FormLabel>Complete section below</FormLabel>
                    {isReadOnly ? (
                      <span>{annualFacilityData.qualifying_storm_notes || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="qualifying_storm_notes"
                        value={annualFacilityData.qualifying_storm_notes}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormCell>
                )}
              </FormRow>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>
                    For qualifying storm events sampled, were Sampling Event Visual Observation Forms completed and
                    maintained on-site?
                  </FormLabel>
                  {isReadOnly ? (
                    <span>{annualFacilityData.event_visual_observation ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="event_visual_observation"
                      value={annualFacilityData.event_visual_observation}
                      onChange={(val) =>
                        setAnnualFacilityData((prev) => ({
                          ...prev,
                          event_visual_observation: val,
                        }))
                      }
                      required
                    />
                  )}
                </FormCell>
                {!annualFacilityData.event_visual_observation && (
                  <FormCell>
                    <FormLabel>Complete section below</FormLabel>
                    {isReadOnly ? (
                      <span>{annualFacilityData.event_visual_observation_notes || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="event_visual_observation_notes"
                        value={annualFacilityData.event_visual_observation_notes}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormCell>
                )}
              </FormRow>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>
                    Have any changes or any proposed changes been made to the Monitoring Implementation Plan based on
                    the evaluation?
                  </FormLabel>
                  {isReadOnly ? (
                    <span>{annualFacilityData.proposed_changes ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="proposed_changes"
                      value={annualFacilityData.proposed_changes}
                      onChange={(val) =>
                        setAnnualFacilityData((prev) => ({
                          ...prev,
                          proposed_changes: val,
                        }))
                      }
                      required
                    />
                  )}
                </FormCell>
                {annualFacilityData.proposed_changes && (
                  <FormCell>
                    <FormLabel>Complete section below</FormLabel>
                    {isReadOnly ? (
                      <span>{annualFacilityData.proposed_changes_notes || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="proposed_changes_notes"
                        value={annualFacilityData.proposed_changes_notes}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormCell>
                )}
              </FormRow>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>
                    Have all authorized non-storm water discharges (NSWDs) at the site been identified in the SWPPP?
                  </FormLabel>
                  {isReadOnly ? (
                    <span>{annualFacilityData.swppp ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="swppp"
                      value={annualFacilityData.swppp}
                      onChange={(val) =>
                        setAnnualFacilityData((prev) => ({
                          ...prev,
                          swppp: val,
                        }))
                      }
                      required
                    />
                  )}
                </FormCell>
                {annualFacilityData.swppp && (
                  <FormCell>
                    <FormLabel>Complete section below</FormLabel>
                    {isReadOnly ? (
                      <span>{annualFacilityData.swppp_notes || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="swppp_notes"
                        value={annualFacilityData.swppp_notes}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormCell>
                )}
              </FormRow>

              <FormHeader style={{ position: "relative" }}>
                <FormCell style={{ display: "flex", justifyContent: "center" }}>Part II. Document Review</FormCell>
                {!isReadOnly && (
                  <FormRow
                    style={{
                      display: "flex",
                      position: "absolute",
                      right: 1,
                      top: 8,
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormLabel
                      onClick={handleAddRow}
                      style={{ marginRight: "10px", fontSize: "20px", cursor: "pointer" }}
                    >
                      +
                    </FormLabel>

                    <FormLabel
                      onClick={() => {
                        if (annualFacilityActivitiesRows.length > 1) {
                          handleRemoveRow(annualFacilityActivitiesRows.length - 1);
                        }
                      }}
                      style={{ marginRight: "10px", fontSize: "20px", cursor: "pointer" }}
                    >
                      -
                    </FormLabel>
                  </FormRow>
                )}
              </FormHeader>

              <FormRow>
                <FormCell>
                  <FormLabel>
                    Industrial Activity Area / Potential Pollutant Source (as identified in your SWPPP)
                  </FormLabel>
                </FormCell>
                <FormCell>
                  <FormLabel>Are the BMPs implemented effective?</FormLabel>
                </FormCell>
                <FormCell>
                  <FormLabel>Is the equipment needed to implement the BMPs in good working condition?</FormLabel>
                </FormCell>
                <FormCell>
                  <FormLabel>
                    Identify corrective actions, additional BMPs, and any SWPPP revisions (if necessary)
                  </FormLabel>
                </FormCell>
              </FormRow>
              {annualFacilityActivitiesRows.map((row, index) => (
                <FormRow key={row.id || index} style={{ display: "flex", alignItems: "center" }}>
                  <FormCell>
                    {isReadOnly ? (
                      <span>{row.activity_area || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="activity_area"
                        value={row.activity_area}
                        onChange={(e) => handleInputChange(index, "activity_area", e.target.value)}
                      />
                    )}
                  </FormCell>

                  <FormCell>
                    {isReadOnly ? (
                      <span>{row.bmp_effectiveness ? "Yes" : "No"}</span>
                    ) : (
                      <RadioGroup
                        name={`bmp_effectiveness_${index}`}
                        value={row.bmp_effectiveness}
                        onChange={(val) => handleInputChange(index, "bmp_effectiveness", val)}
                        disabled={!row.activity_area}
                        required={row.activity_area !== ""}
                      />
                    )}
                  </FormCell>

                  <FormCell>
                    {isReadOnly ? (
                      <span>{row.bmp_good_condition ? "Yes" : "No"}</span>
                    ) : (
                      <RadioGroup
                        name={`bmp_good_condition_${index}`}
                        value={row.bmp_good_condition}
                        onChange={(val) => handleInputChange(index, "bmp_good_condition", val)}
                        disabled={!row.activity_area}
                        required={row.activity_area !== ""}
                      />
                    )}
                  </FormCell>
                  <FormCell>
                    {isReadOnly ? (
                      <span>{row.corrective_action || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="corrective_action"
                        value={row.corrective_action}
                        onChange={(e) => handleInputChange(index, "corrective_action", e.target.value)}
                        disabled={row.bmp_good_condition === false || row.bmp_good_condition === undefined}
                        required={row.bmp_good_condition === false || row.bmp_good_condition === undefined}
                      />
                    )}
                  </FormCell>
                </FormRow>
              ))}

              <FormHeader>
                <FormCell style={{ display: "flex", justifyContent: "center" }}>
                  Part III. Comprehensive Review and SWPPP Assessment
                </FormCell>
              </FormHeader>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>
                    Based on the inspection, is there evidence of, or potential for, pollutants discharging from the
                    facility?
                  </FormLabel>
                  {isReadOnly ? (
                    <span>{annualFacilityData.inspection_evidence ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="inspection_evidence"
                      value={annualFacilityData.inspection_evidence}
                      onChange={(val) =>
                        setAnnualFacilityData((prev) => ({
                          ...prev,
                          inspection_evidence: val,
                        }))
                      }
                      required
                    />
                  )}
                </FormCell>
                {annualFacilityData.inspection_evidence && (
                  <FormCell>
                    <FormLabel>Complete section below</FormLabel>
                    {isReadOnly ? (
                      <span>{annualFacilityData.inspection_evidence_notes || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="inspection_evidence_notes"
                        value={annualFacilityData.inspection_evidence_notes}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormCell>
                )}
              </FormRow>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>
                    Are all drainage areas where industrial activities are performed included in the SWPPP?
                  </FormLabel>
                  {isReadOnly ? (
                    <span>{annualFacilityData.performed_swppp ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="performed_swppp"
                      value={annualFacilityData.performed_swppp}
                      onChange={(val) =>
                        setAnnualFacilityData((prev) => ({
                          ...prev,
                          performed_swppp: val,
                        }))
                      }
                      required
                    />
                  )}
                </FormCell>
                {!annualFacilityData.performed_swppp && (
                  <FormCell>
                    <FormLabel>Complete section below</FormLabel>
                    {isReadOnly ? (
                      <span>{annualFacilityData.performed_swppp_notes || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="performed_swppp_notes"
                        value={annualFacilityData.performed_swppp_notes}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormCell>
                )}
              </FormRow>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Based on the evaluation, does the SWPPP require revisions?</FormLabel>
                  {isReadOnly ? (
                    <span>{annualFacilityData.swpp_revisions ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="swpp_revisions"
                      value={annualFacilityData.swpp_revisions}
                      onChange={(val) =>
                        setAnnualFacilityData((prev) => ({
                          ...prev,
                          swpp_revisions: val,
                        }))
                      }
                      required
                    />
                  )}
                </FormCell>
                {annualFacilityData.swpp_revisions && (
                  <FormCell>
                    <FormLabel>Complete section below</FormLabel>
                    {isReadOnly ? (
                      <span>{annualFacilityData.swpp_revisions_notes || <em>N/A</em>}</span>
                    ) : (
                      <FormInput
                        type="text"
                        name="swpp_revisions_notes"
                        value={annualFacilityData.swpp_revisions_notes}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormCell>
                )}
              </FormRow>
            </FormTable>
          )}
        </div>
      </form>
    </div>
  );
};

export default AnnualFacilityForm;
