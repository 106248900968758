export const initialFormData: InspectionFormData = {
  id: undefined,
  user_id: undefined,
  url: "",
  wdid: "",
  title: "",
  month: "",
  facility: "",
  date_time: "",
  preceding_weather_condition: "",
  current_weather_condition: "",
  nswd: undefined,
  nswd_source: "",
  nswd_observe: undefined,
  nswd_above: "",
  nswd_potential_observed: undefined,
  nswd_visual_observed: "",
  nswd_authorized: undefined,
  nswd_bmp: undefined,
  nswd_unauthorized: "",
  nswd_action: "",
  action_to_eliniminate: "",
  bmp_place: undefined,
  bmp_good_condition: undefined,
  bmp_need_maintenance: undefined,
  bmp_swppp: undefined,
  bmp_place_notes: "",
  bmp_good_condition_notes: "",
  bmp_need_maintenance_notes: "",
  bmp_swpp_notes: "",
};

export interface InspectionFormData {
  id?: number;
  user_id?: number;
  url: string;
  wdid: string | null;
  month: string;
  title: string;
  facility: string;
  date_time: string;
  preceding_weather_condition: string;
  current_weather_condition: string;
  nswd: boolean | undefined;
  nswd_source: string;
  nswd_observe: boolean | undefined;
  nswd_above: string;
  nswd_potential_observed: boolean | undefined;
  nswd_visual_observed: string;
  nswd_authorized: boolean | undefined;
  nswd_bmp: boolean | undefined;
  nswd_unauthorized: string;
  nswd_action: string;
  action_to_eliniminate: string;
  bmp_place: boolean | undefined;
  bmp_good_condition: boolean | undefined;
  bmp_need_maintenance: boolean | undefined;
  bmp_swppp: boolean | undefined;
  bmp_place_notes: string;
  bmp_good_condition_notes: string;
  bmp_need_maintenance_notes: string;
  bmp_swpp_notes: string;
}

export interface SampleObservationTableRecord {
  id: number | null;
  user_id: number | null;
  reporting_year: string;
  sample_date: string;
  status: string;
  month: string;
  facility: string;
  data_time: string;
  title: string;
  at_least_one_drainage_area: string;
  preceded: string;
  samples_a: string;
  wdid: string;
}

export const initialSampleObservationTable: SampleObservationTableRecord = {
  id: null,
  user_id: null,
  reporting_year: "",
  sample_date: "",
  status: "",
  month: "",
  facility: "",
  data_time: "",
  title: "",
  at_least_one_drainage_area: "",
  preceded: "",
  samples_a: "",
  wdid: "",
};

export interface FacilityMonitoringAreaRowsProps {
  id: number;
  area: string;
  issue: string;
  revision: string;
}

export interface FacilityMonitoringAreaProps {
  id: number;
  facility_monitoring_id: number;
  area: string;
  issue: string;
  revision: string;
}

export interface StormWaterInspectionFormProps {
  formData: InspectionFormData | null;
  isReadOnly?: boolean;
  wdid: string | null;
  year?: string | null;
}

export interface AnnualFacilityDataProps {
  id: number | undefined;
  user_id: number | undefined;
  reporting_year: string;
  completed_by?: string;
  status: string;
  url?: string;
  facility: string;
  date_time: string;
  name: string;
  title: string;
  visual_observations: boolean | undefined;
  visual_observations_notes: string;
  qualifying_storm:boolean | undefined;
  qualifying_storm_notes: string;
  event_visual_observation: boolean | undefined;
  event_visual_observation_notes: string;
  proposed_changes: boolean | undefined;
  proposed_changes_notes: string;
  swppp: boolean | undefined;
  swppp_notes: string;
  inspection_evidence: boolean | undefined;
  inspection_evidence_notes: string;
  performed_swppp: boolean | undefined;
  performed_swppp_notes: string;
  swpp_revisions: boolean | undefined;
  swpp_revisions_notes: string;
  wdid: string;
}

export const initialAnnualFacilityData: AnnualFacilityDataProps = {
  id: undefined,
  user_id: undefined,
  completed_by: "",
  status: "",
  url: "",
  reporting_year: "",
  facility: "",
  date_time: "",
  name: "",
  title: "",
  visual_observations: undefined,
  visual_observations_notes: "",
  qualifying_storm: undefined,
  qualifying_storm_notes: "",
  event_visual_observation: undefined,
  event_visual_observation_notes: "",
  proposed_changes: undefined,
  proposed_changes_notes: "",
  swppp: undefined,
  swppp_notes: "",
  inspection_evidence: undefined,
  inspection_evidence_notes: "",
  performed_swppp: undefined,
  performed_swppp_notes: "",
  swpp_revisions: undefined,
  swpp_revisions_notes: "",
  wdid: "",
};

export interface AnnualFacilityActivitiesRowsProps {
  id: undefined;
  annual_facility_id?: number;
  activity_area: string;
  bmp_effectiveness: boolean | undefined;
  bmp_good_condition: boolean | undefined;
  corrective_action: string;
}

export interface MainObservation {
  id?: number;
  user_id: number | null;
  wdid: string;
  url?: string;
  facility: string;
  sample_date: string;
  title: string;
  month: string;
  reporting_year: string;
  status: string;
  at_least_one_drainage_area: boolean | null;
  preceded: boolean | null;
  samples_a: boolean | null;
  active_tab: number;
}

export interface ObservationTab {
  id?: number;
  outfall: string;
  observation: string;
  ph: string;
  water_discharge: boolean | null;
  trash: boolean | null;
  floating_material: boolean | null;
  oil_sheen: boolean | null;
  discoloration: boolean | null;
  odors: boolean | null;
  notes: string;
  describe_wsppp: string;
  event_observations_id: number | null;
}

export interface SampleObservationFormProps {
  isReadOnly: boolean;
  wdid: string | null;
  formData: any;
}

export const initialMainObservation: MainObservation = {
  id: undefined,
  user_id: null,
  wdid: "",
  facility: "",
  sample_date: "",
  title: "",
  month: "",
  reporting_year: "",
  status: "",
  at_least_one_drainage_area: null,
  preceded: null,
  samples_a: null,
  active_tab: 0,
};

export const initialObservationTab: ObservationTab = {
  id: undefined,
  outfall: "",
  observation: "",
  ph: "",
  water_discharge: null,
  trash: null,
  floating_material: null,
  oil_sheen: null,
  discoloration: null,
  odors: null,
  notes: "",
  describe_wsppp: "",
  event_observations_id: null,
};

