import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import api from "api";
import {
  createFacilityMonitoring,
  deleteFacilityMonitoring,
  updateFacilityMonitoring,
} from "helpers/facilityMonitoring";
import { useToaster } from "context/TosterContext";
import { Button } from "ui/base/Button";
import { ModalWarning } from "ui/base/ModalWarning";
import {
  FormRow,
  FormCell,
  FormTable,
  FormHeader,
  FormInput,
  FormLabel,
  FormSelectLabel,
} from "./MonthlyVisualObservationStyled";

import {
  FacilityMonitoringAreaProps,
  FacilityMonitoringAreaRowsProps,
  initialFormData,
  InspectionFormData,
  StormWaterInspectionFormProps,
} from "../types";

interface RootState {
  user: {
    id: number;
  } | null;
}

interface RadioGroupProps {
  name: string;
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  required?: boolean;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ name, value, onChange, disabled = false, required = false }) => (
  <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 15 }}>
    <FormSelectLabel>
      <input
        type="radio"
        name={name}
        value="true"
        checked={value === true}
        onChange={() => onChange(true)}
        disabled={disabled}
        required={required}
      />
      Yes
    </FormSelectLabel>
    <p>or</p>
    <FormSelectLabel>
      <input
        type="radio"
        name={name}
        value="false"
        checked={value === false}
        onChange={() => onChange(false)}
        disabled={disabled}
        required={required}
      />
      No
    </FormSelectLabel>
  </div>
);

interface ReadOnlyFieldProps {
  label: string;
  value: string | null | undefined;
}

const ReadOnlyField: React.FC<ReadOnlyFieldProps> = ({ label, value }) => (
  <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
    <FormLabel>{label}</FormLabel>
    <span>{value || <em>N/A</em>}</span>
  </FormCell>
);

interface ExistingAreaRowProps {
  area: FacilityMonitoringAreaProps;
  isReadOnly: boolean;
  onChange: (id: number, field: keyof FacilityMonitoringAreaRowsProps, value: string) => void;
  onDelete: (id: number) => void;
}

const ExistingAreaRow: React.FC<ExistingAreaRowProps> = ({ area, isReadOnly, onChange, onDelete }) => {
  const handleChange = (field: keyof FacilityMonitoringAreaRowsProps, value: string) => {
    onChange(area.id, field, value);
  };

  return isReadOnly ? (
    <FormRow>
      <FormCell>{area.area || <em>N/A</em>}</FormCell>
      <FormCell>{area.issue || <em>N/A</em>}</FormCell>
      <FormCell>{area.revision || <em>N/A</em>}</FormCell>
    </FormRow>
  ) : (
    <FormRow>
      <FormCell>
        <FormInput type="text" value={area.area || ""} onChange={(e) => handleChange("area", e.target.value)} />
      </FormCell>
      <FormCell>
        <FormInput type="text" value={area.issue || ""} onChange={(e) => handleChange("issue", e.target.value)} />
      </FormCell>
      <FormCell>
        <FormInput type="text" value={area.revision || ""} onChange={(e) => handleChange("revision", e.target.value)} />
      </FormCell>
      <FormCell style={{ maxWidth: "100px" }}>
        <FormLabel
          onClick={() => onDelete(area.id)}
          style={{
            backgroundColor: "red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "7px",
            color: "white",
            opacity: 0.9,
            cursor: "pointer",
          }}
        >
          Delete
        </FormLabel>
      </FormCell>
    </FormRow>
  );
};

interface NewAreaRowProps {
  row: FacilityMonitoringAreaRowsProps;
  index: number;
  isReadOnly: boolean;
  onChange: (index: number, field: keyof FacilityMonitoringAreaRowsProps, value: string) => void;
  onDelete: (index: number) => void;
}

const NewAreaRow: React.FC<NewAreaRowProps> = ({ row, index, isReadOnly, onChange, onDelete }) => {
  const handleChange = (field: keyof FacilityMonitoringAreaRowsProps, value: string) => {
    onChange(index, field, value);
  };

  return (
    <FormRow key={`new-area-${index}`}>
      <FormCell>
        <FormInput
          type="text"
          value={row.area || ""}
          onChange={(e) => handleChange("area", e.target.value)}
          disabled={isReadOnly}
        />
      </FormCell>
      <FormCell>
        <FormInput
          type="text"
          value={row.issue || ""}
          onChange={(e) => handleChange("issue", e.target.value)}
          disabled={isReadOnly}
        />
      </FormCell>
      <FormCell>
        <FormInput
          type="text"
          value={row.revision || ""}
          onChange={(e) => handleChange("revision", e.target.value)}
          disabled={isReadOnly}
        />
      </FormCell>
      {!isReadOnly && (
        <FormCell style={{ maxWidth: "100px" }}>
          <FormLabel
            onClick={() => onDelete(index)}
            style={{
              backgroundColor: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "7px",
              color: "white",
              opacity: 0.9,
              cursor: "pointer",
            }}
          >
            Delete
          </FormLabel>
        </FormCell>
      )}
    </FormRow>
  );
};

const getInitialMonthIndex = (monthNames: string[], search: string): number => {
  const params = new URLSearchParams(search);
  const urlMonth = params.get("month");
  if (urlMonth) {
    const index = monthNames.findIndex((m) => m.toLowerCase() === urlMonth.toLowerCase());
    if (index !== -1) {
      return index;
    }
  }
  return 0;
};

const StormWaterInspectionForm: React.FC<StormWaterInspectionFormProps> = ({
  formData,
  year,
  wdid,
  isReadOnly = false,
}) => {
  const { addToast } = useToaster();
  const componentRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const user = useSelector((state: RootState) => state.user);

  const [inspectionData, setInspectionData] = useState<InspectionFormData>(initialFormData);
  const [existingAreas, setExistingAreas] = useState<FacilityMonitoringAreaProps[]>([]);
  const [newAreas, setNewAreas] = useState<FacilityMonitoringAreaRowsProps[]>([
    { id: 0, area: "", issue: "", revision: "" },
  ]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedMonthIndex, setSelectedMonthIndex] = useState<number>(() =>
    getInitialMonthIndex(monthNames, location.search)
  );

  const currentMonth = monthNames[selectedMonthIndex];

  useEffect(() => {
    setSelectedMonthIndex(getInitialMonthIndex(monthNames, location.search));
  }, [location.search]);

  useEffect(() => {
    setInspectionData(formData || initialFormData);
  }, [formData]);

  useEffect(() => {
    const fetchExistingAreas = async () => {
      if (inspectionData.id) {
        try {
          const res = await api.get(`facility_monitoring_area?facility_monitoring_id=eq.${inspectionData.id}`);
          if (Array.isArray(res.data) && res.data.length > 0) {
            setExistingAreas(res.data);
          }
        } catch (error) {
          console.error("Error fetching existing areas:", error);
          addToast("Error fetching existing areas", "error");
        }
      } else {
        setExistingAreas([]);
      }
    };
    fetchExistingAreas();
  }, [inspectionData.id, addToast]);

  const handleChangeInspectionData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const { name, value, type } = e.target;
      setInspectionData((prev) => ({
        ...prev,
        [name]: type === "radio" ? value === "true" : value,
      }));
    },
    []
  );

  const handleExistingAreaChange = useCallback(
    (id: number, field: keyof FacilityMonitoringAreaRowsProps, value: string) => {
      setExistingAreas((prev) => prev.map((item) => (item.id === id ? { ...item, [field]: value } : item)));
    },
    []
  );

  const handleNewAreaChange = useCallback(
    <K extends keyof FacilityMonitoringAreaRowsProps>(
      index: number,
      field: K,
      value: FacilityMonitoringAreaRowsProps[K]
    ) => {
      setNewAreas((prevRows) => {
        const updated = [...prevRows];
        updated[index][field] = value;
        return updated;
      });
    },
    []
  );

  const handleAddNewAreaRow = useCallback(() => {
    setNewAreas((prevRows) => [...prevRows, { id: 0, area: "", issue: "", revision: "" }]);
  }, []);

  const handleRemoveExistingArea = useCallback(
    async (id: number) => {
      try {
        await deleteFacilityMonitoring(id, "facility_monitoring_area");
        setExistingAreas((prev) => prev.filter((item) => item.id !== id));
        addToast("Existing row deleted successfully!", "success");
      } catch (error) {
        console.error("Error deleting row from DB:", error);
        addToast("Error deleting existing row from DB", "error");
      }
    },
    [addToast]
  );

  const handleRemoveNewArea = useCallback((index: number) => {
    setNewAreas((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handleSubmitForm = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      try {
        let facilityMonitoringId: number | undefined;

        if (!inspectionData.id) {
          const created = await createFacilityMonitoring("facility_monitoring", {
            ...inspectionData,
            wdid,
            user_id: user?.id,
            status: `Completed ${new Date().toLocaleDateString()}`,
            year: year,
            month: currentMonth,
          });

          if (Array.isArray(created) && created.length > 0 && created[0].id) {
            facilityMonitoringId = created[0].id;
            setInspectionData((prev) => ({ ...prev, id: facilityMonitoringId }));
          } else if (created?.data?.[0]?.id) {
            facilityMonitoringId = created.data[0].id;
            setInspectionData((prev) => ({ ...prev, id: facilityMonitoringId }));
          } else {
            throw new Error("Can't get id from facility_monitoring.");
          }
        } else {
          await updateFacilityMonitoring(inspectionData.id, "facility_monitoring", {
            ...inspectionData,
            month: currentMonth,
          });
          facilityMonitoringId = inspectionData.id;
        }

        if (!facilityMonitoringId) {
          throw new Error("facilityMonitoringId не визначено.");
        }

        if (existingAreas.length > 0) {
          await Promise.all(
            existingAreas.map((area) => {
              const payload = {
                ...area,
                facility_monitoring_id: facilityMonitoringId,
              };
              return updateFacilityMonitoring(area.id, "facility_monitoring_area", payload);
            })
          );
        }

        const areasToProcess = newAreas.filter((row) => row.area || row.issue || row.revision);

        if (areasToProcess.length > 0) {
          await Promise.all(
            areasToProcess.map(async (row) => {
              const payload = {
                ...row,
                id: undefined,
                facility_monitoring_id: facilityMonitoringId,
              };
              if (row.id && row.id > 0) {
                return updateFacilityMonitoring(row.id, "facility_monitoring_area", payload);
              } else {
                return createFacilityMonitoring("facility_monitoring_area", payload);
              }
            })
          );
        }

        addToast("Successfully updated!", "success");
        setTimeout(() => {
          window.location.href = "/monitoring";
        }, 2000);
      } catch (error: any) {
        console.error("Error updating data:", error);
        addToast("Error updating data", "error");
      }
    },
    [inspectionData, existingAreas, newAreas, wdid, user?.id, year, currentMonth, addToast]
  );

  const handleDeleteClick = useCallback(() => {
    if (!inspectionData.id) {
      addToast("No 'id' found. Could not delete record!", "error");
      return;
    }
    setIsOpen(true);
  }, [inspectionData.id, addToast]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleConfirmDelete = useCallback(
    async (confirmed: boolean) => {
      setIsOpen(false);
      if (confirmed && inspectionData.id) {
        try {
          await deleteFacilityMonitoring(inspectionData.id, "facility_monitoring_area?facility_monitoring_id");
          await deleteFacilityMonitoring(inspectionData.id, "facility_monitoring");
          addToast("Form deleted successfully!", "success");
          setTimeout(() => {
            window.location.href = "/monitoring";
          }, 2000);
        } catch (error) {
          console.error("Error deleting data:", error);
          addToast("Error deleting form!", "error");
        }
      }
    },
    [inspectionData.id, addToast]
  );

  const handleCancelDelete = useCallback(() => {
    setIsOpen(false);
  }, []);

  const reactToPrintContentCallback = useCallback(() => componentRef.current, []);
  return (
    <div
      style={{
        display: "flex",
        width: "80%",
        margin: "40px auto",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <ModalWarning isOpen={isOpen} onConfirm={handleConfirmDelete} onCancel={handleCancelDelete} />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="white" type="button" background="darkBlue">
          <a href="/monitoring" style={{ color: "inherit", textDecoration: "none" }}>
            Go back
          </a>
        </Button>
      </div>

      <form
        onSubmit={handleSubmitForm}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
          flexWrap: "wrap",
        }}
      >
        <div style={{ justifyContent: "flex-end", width: "100%", display: "flex" }}>
          {isReadOnly ? (
            <ReactToPrint
              content={reactToPrintContentCallback}
              documentTitle="Monitoring Form"
              removeAfterPrint
              trigger={() => (
                <Button type="button" color="white" background="blue">
                  Print completed form
                </Button>
              )}
            />
          ) : (
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {!inspectionData.url && (
                <Button type="submit" color="white" background="blue">
                  Save Form
                </Button>
              )}
              <Button color="white" type="button" onClick={handleDeleteClick}>
                Delete Form
              </Button>
            </div>
          )}
        </div>

        <div
          ref={componentRef}
          id="printable-section"
          style={{
            width: !isReadOnly ? "100%" : isReadOnly && !inspectionData.url ? "100%" : "auto",
            margin: "20px auto",
            pointerEvents: isReadOnly ? "none" : "auto",
            opacity: isReadOnly ? 0.9 : 1,
          }}
        >
          {inspectionData.url ? (
            <img src={inspectionData.url} alt="Monthly-Facility-Form" style={{ width: "100%", height: "auto" }} />
          ) : (
            <FormTable>
              <FormHeader>
                <FormCell style={{ display: "flex", justifyContent: "center" }}>General Information</FormCell>
              </FormHeader>

              <FormRow
                style={{
                  width: "100%",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {isReadOnly ? (
                  <ReadOnlyField label="Month" value={currentMonth} />
                ) : (
                  monthNames.map((item, idx) => (
                    <p
                      key={item}
                      style={
                        idx === selectedMonthIndex
                          ? {
                              fontWeight: "bold",
                              textDecoration: "underline",
                              cursor: "default",
                            }
                          : { cursor: "pointer" }
                      }
                      onClick={() => setSelectedMonthIndex(idx)}
                    >
                      {item}
                    </p>
                  ))
                )}
              </FormRow>

              <FormRow className="row">
                <FormCell style={{ display: "flex", gap: 5 }}>
                  <FormLabel>Facility:</FormLabel>
                  {isReadOnly ? <span>{inspectionData.wdid}</span> : wdid}
                </FormCell>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Date and Time</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.date_time?.split("T")[0]}</span>
                  ) : (
                    <FormInput
                      type="date"
                      name="date_time"
                      value={inspectionData.date_time?.split("T")[0] || ""}
                      onChange={handleChangeInspectionData}
                      required
                    />
                  )}
                </FormCell>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Observer Name and Title</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.title}</span>
                  ) : (
                    <FormInput
                      type="text"
                      name="title"
                      value={inspectionData.title || ""}
                      onChange={handleChangeInspectionData}
                      required
                    />
                  )}
                </FormCell>
              </FormRow>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Preceding Weather Conditions (Previous 48 hours)</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.preceding_weather_condition}</span>
                  ) : (
                    <FormInput
                      type="text"
                      name="preceding_weather_condition"
                      value={inspectionData.preceding_weather_condition || ""}
                      onChange={handleChangeInspectionData}
                      required
                    />
                  )}
                </FormCell>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Current Weather Conditions</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.current_weather_condition}</span>
                  ) : (
                    <FormInput
                      type="text"
                      name="current_weather_condition"
                      value={inspectionData.current_weather_condition || ""}
                      onChange={handleChangeInspectionData}
                      required
                    />
                  )}
                </FormCell>
              </FormRow>

              <FormHeader>
                <FormCell style={{ display: "flex", justifyContent: "center" }}>
                  Non-Storm Water Discharge (NSWD) Observations
                </FormCell>
              </FormHeader>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Was a NSWD observed?</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.nswd ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="nswd"
                      value={inspectionData.nswd}
                      onChange={(val) => setInspectionData((prev) => ({ ...prev, nswd: val }))}
                      required
                    />
                  )}
                </FormCell>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>What was the source?</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.nswd_source}</span>
                  ) : (
                    <FormInput
                      type="text"
                      name="nswd_source"
                      value={inspectionData.nswd_source || ""}
                      onChange={handleChangeInspectionData}
                      required
                    />
                  )}
                </FormCell>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Was it Authorized or UnAuthorized NSWD?</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.nswd_authorized ? "Authorized" : "Unauthorized"}</span>
                  ) : (
                    <RadioGroup
                      name="nswd_authorized"
                      value={inspectionData.nswd_authorized}
                      onChange={(val) => setInspectionData((prev) => ({ ...prev, nswd_authorized: val }))}
                      required
                    />
                  )}
                </FormCell>
              </FormRow>

              <FormRow>
                <FormCell />
                <FormCell
                  style={
                    inspectionData.nswd
                      ? { display: "flex", flexDirection: "column", gap: 5 }
                      : { display: "flex", opacity: 0.2, flexDirection: "column", gap: 5 }
                  }
                >
                  <FormLabel>
                    Did you observe trash, debris, suspended material, oil sheen, discoloration, odors?
                  </FormLabel>
                  {isReadOnly && inspectionData.nswd ? (
                    <span>{inspectionData.nswd_observe ? "Yes" : "No"}</span>
                  ) : (
                    inspectionData.nswd && (
                      <RadioGroup
                        name="nswd_observe"
                        value={inspectionData.nswd_observe}
                        onChange={(val) => setInspectionData((prev) => ({ ...prev, nswd_observe: val }))}
                        required
                      />
                    )
                  )}
                </FormCell>
                <FormCell
                  style={
                    inspectionData.nswd_authorized
                      ? { display: "flex", flexDirection: "column", gap: 5 }
                      : { display: "flex", opacity: 0.2, flexDirection: "column", gap: 5 }
                  }
                >
                  <FormLabel>Does the Authorized NSWD and associated BMPs comply with Section IV.B.3</FormLabel>
                  {isReadOnly && inspectionData.nswd_authorized ? (
                    <span>{inspectionData.nswd_bmp ? "Yes" : "No"}</span>
                  ) : (
                    inspectionData.nswd_authorized && (
                      <RadioGroup
                        name="nswd_bmp"
                        value={inspectionData.nswd_bmp}
                        onChange={(val) => setInspectionData((prev) => ({ ...prev, nswd_bmp: val }))}
                        required
                      />
                    )
                  )}
                </FormCell>
              </FormRow>

              <FormRow>
                <FormCell />
                <FormCell
                  style={
                    inspectionData.nswd_observe
                      ? { display: "flex", flexDirection: "column", gap: 5 }
                      : { display: "flex", opacity: 0.2, flexDirection: "column", gap: 5 }
                  }
                >
                  <FormLabel>Describe visual observation</FormLabel>
                  {isReadOnly && inspectionData.nswd_observe ? (
                    <span>{inspectionData.nswd_above}</span>
                  ) : (
                    inspectionData.nswd_observe && (
                      <FormInput
                        type="text"
                        name="nswd_above"
                        value={inspectionData.nswd_above || ""}
                        onChange={handleChangeInspectionData}
                        required
                      />
                    )
                  )}
                </FormCell>
                <FormCell
                  style={
                    !inspectionData.nswd_authorized
                      ? { display: "flex", flexDirection: "column", gap: 5 }
                      : { display: "flex", opacity: 0.2, flexDirection: "column", gap: 5 }
                  }
                >
                  <FormLabel>What was the action to eliminate the discharge?</FormLabel>
                  {isReadOnly && !inspectionData.nswd_authorized ? (
                    <span>{inspectionData.action_to_eliniminate}</span>
                  ) : (
                    !inspectionData.nswd_authorized && (
                      <FormInput
                        type="text"
                        name="action_to_eliniminate"
                        value={inspectionData.action_to_eliniminate || ""}
                        onChange={handleChangeInspectionData}
                        required
                      />
                    )
                  )}
                </FormCell>
              </FormRow>

              <FormRow>
                <FormCell style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <FormLabel>Was indication of a prior or potential NSWD observed?</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.nswd_potential_observed ? "Yes" : "No"}</span>
                  ) : (
                    <RadioGroup
                      name="nswd_potential_observed"
                      value={inspectionData.nswd_potential_observed}
                      onChange={(val) => setInspectionData((prev) => ({ ...prev, nswd_potential_observed: val }))}
                      required
                    />
                  )}
                </FormCell>
                <FormCell
                  style={
                    inspectionData.nswd_potential_observed
                      ? { display: "flex", flexDirection: "column", gap: 5 }
                      : { display: "flex", opacity: 0.2, flexDirection: "column", gap: 5 }
                  }
                >
                  <FormLabel>What was the source and describe visual evidence observed?</FormLabel>
                  {isReadOnly ? (
                    <span>{inspectionData.nswd_visual_observed}</span>
                  ) : (
                    inspectionData.nswd_potential_observed && (
                      <FormInput
                        type="text"
                        name="nswd_visual_observed"
                        value={inspectionData.nswd_visual_observed || ""}
                        onChange={handleChangeInspectionData}
                        required
                      />
                    )
                  )}
                </FormCell>
                <FormCell
                  style={
                    inspectionData.nswd_bmp
                      ? { display: "flex", flexDirection: "column", gap: 5 }
                      : { display: "flex", opacity: 0.2, flexDirection: "column", gap: 5 }
                  }
                >
                  <FormLabel>What was the action taken?</FormLabel>
                  {isReadOnly && inspectionData.nswd_bmp ? (
                    <span>{inspectionData.nswd_action}</span>
                  ) : (
                    inspectionData.nswd_bmp && (
                      <FormInput
                        type="text"
                        name="nswd_action"
                        value={inspectionData.nswd_action || ""}
                        onChange={handleChangeInspectionData}
                        required
                      />
                    )
                  )}
                </FormCell>
              </FormRow>

              <FormRow>
                <FormCell>
                  <FormLabel>Area</FormLabel>
                </FormCell>
                <FormCell>
                  <FormLabel>Issue Identified</FormLabel>
                </FormCell>
                <FormCell>
                  <FormLabel>Corrective Action / SWPPP Revisions</FormLabel>
                </FormCell>
                {!isReadOnly && <FormCell style={{ maxWidth: "100px" }} />}
              </FormRow>

              {existingAreas.map((area) => (
                <ExistingAreaRow
                  key={area.id}
                  area={area}
                  isReadOnly={isReadOnly}
                  onChange={handleExistingAreaChange}
                  onDelete={handleRemoveExistingArea}
                />
              ))}

              {!isReadOnly &&
                newAreas.map((row, index) => (
                  <NewAreaRow
                    key={`new-area-${index}`}
                    row={row}
                    index={index}
                    isReadOnly={isReadOnly}
                    onChange={handleNewAreaChange}
                    onDelete={handleRemoveNewArea}
                  />
                ))}

              {!isReadOnly && (
                <FormRow style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="button" color="white" background="grey" onClick={handleAddNewAreaRow}>
                    Add New Row
                  </Button>
                </FormRow>
              )}
            </FormTable>
          )}
        </div>
      </form>
    </div>
  );
};

export default StormWaterInspectionForm;
