import styled from "styled-components";
import { themeColor } from "ui/theme";

export const FormControlContainer = styled.div<{ wide?: boolean; margin?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ wide }) => (wide ? "100%" : "calc(100% - 23px)")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  overflow-x: auto;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const FormTable = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${themeColor("borderGrey")};
  width: 100%;
`;

export const FormHeader = styled.div`
  background-color: ${themeColor("lightBlue")};
  color: ${themeColor("blue")};
  font-weight: bold;
  text-align: center;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const FormCell = styled.div<{ rowDirection?: boolean }>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ rowDirection }) => (rowDirection ? "row" : "column")};
  gap: ${({ rowDirection }) => (rowDirection ? "10px" : "0px")};
  border: solid 1px ${themeColor("borderGrey")};
  padding: 8px;
  text-align: left;
}
`;

export const FormLabel = styled.label`
  font-weight: bold;
  font-size: 15px;
`;

export const FormInput = styled.input`
  width: 100%;
  border: 1px solid ${themeColor("lightBlue")};
  color: ${themeColor("darkBlue")};
  padding: 8px;
  text-align: left;
`;

export const FormSelectLabel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
`;
