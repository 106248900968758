import api from "api";
import { useState, useEffect } from "react";

import SampleObservationForm from "ui/blocks/monitoring/Forms/SampleObservationForm";
import { SampleObservationTableRecord, initialSampleObservationTable } from "ui/blocks/monitoring/types";

export default function SampleObservationFormPage() {
  const queryParams = new URLSearchParams(window.location.search);

  const id = queryParams.get("id");
  const mode = queryParams.get("mode");
  const wdid = queryParams.get("wdid");

  const [sampleObservationData, setSampleObservationData] =
    useState<any>(initialSampleObservationTable);

  useEffect(() => {
    if (!id) return;
    api.get(`event_observations?id=eq.${id}`).then((res) => {
      if (res.data && res.data.length > 0) {
        setSampleObservationData(res.data[0]);
      }
    });
  }, [id]);

  if (!sampleObservationData) return <div>Loading...</div>;
  return <SampleObservationForm wdid={wdid} formData={sampleObservationData} isReadOnly={mode === "view"} />;
}
