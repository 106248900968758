import api from "api";
import { useEffect, useState } from "react";

import store from "ui/redux/store";
import { DocumentsFormStyled, DocumentFormLinkStyled, DocumentFormButtonStyled } from "./DocumentsFormStyled";
import { Table } from "ui/base/Table";
import {
  Flask,
  Folder,
  SiteMap,
  SWPPPDocument,
  LabReports,
  GroupLeaderInspectionNotes,
  VisualObservationForms,
} from "ui/icons";

const DovumentsForms = () => {
  const [data, setData] = useState<any[]>([]);
  const [wdid, setWdid] = useState(store.getState().wdid);
  const [hoveredButton, setHoveredButton] = useState<string | null>("");
  store.subscribe(() => setWdid(store.getState().wdid));

  useEffect(() => {
    api
      .get(
        `facilities2resources?select=id,resources!inner(r_type,doc_type,title,link,showonmain),facilities!inner(wdid,name)&and(resources.r_type.eq*facility_doc*,resources.r_type.eq*form*)&facilities.wdid=like.*${wdid}*&resources.showonmain.eq*${true}*`
      )
      .then((res) => {
        setData(res.data);
      });
  }, [wdid]);

  const iconMap: { [key: string]: React.FC<{ color?: string }> } = {
    swppp: SWPPPDocument,
    site_map: SiteMap,
    coc: Flask,
    lab_reports: LabReports,
    group_leader_inspection_notes: GroupLeaderInspectionNotes,
    era_reports: () => null,
    visual_observation_forms: VisualObservationForms,
    training_docs: () => null,
  };

  return (
    <Table widthCol={25} isDownload={true}>
      <DocumentsFormStyled>
        {data
          .filter(({ resources }) => resources.showonmain)
          .map(({ resources }: any) => {
            const isHovered = hoveredButton === resources.title;
            const IconComponent = iconMap[resources.doc_type] || Folder;

            return (
              <div key={resources.id || resources.title}>
                <DocumentFormLinkStyled href={resources.link || "#"}>
                  <DocumentFormButtonStyled
                    onMouseEnter={() => setHoveredButton(resources.title)}
                    onMouseLeave={() => setHoveredButton(null)}
                  >
                    <span style={{ width: "20px", marginTop: "5px" }}>
                      <IconComponent color={isHovered ? "white" : "darkBlue"} />
                    </span>
                    <div
                      style={{
                        borderRight: `1px solid ${isHovered ? "white" : "darkBlue"}`,
                        height: "25px",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "3px",
                      }}
                    >
                      {resources.title}
                    </span>
                  </DocumentFormButtonStyled>
                </DocumentFormLinkStyled>
              </div>
            );
          })}
      </DocumentsFormStyled>
    </Table>
  );
};

export default DovumentsForms;
