interface IconProps {
  color?: string;
}

export const LabReports: React.FC<IconProps> = ({ color = "darkBlue" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
      <path
        d="m9,12c3.309,0,6-2.691,6-6S12.309,0,9,0,3,2.691,3,6s2.691,6,6,6Zm0-11c2.757,0,5,2.243,5,5s-2.243,5-5,5-5-2.243-5-5S6.243,1,9,1Zm3.834,14.248l-2.326,3.043c-.38.456-.918.709-1.491.709s-1.111-.252-1.478-.691l-2.365-3.091c-.195-.234-.56-.292-.834-.118-.341.216-.675.475-.994.769-1.49,1.379-2.346,3.457-2.346,5.703v1.929c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-1.929c0-2.523.973-4.87,2.667-6.438.363-.335.746-.631,1.138-.879.696-.443,1.622-.295,2.15.339l2.365,3.091c.162.194.421.315.696.315s.533-.121.71-.333l2.326-3.043c.544-.654,1.47-.797,2.168-.354.201.129.397.268.587.415.218.17.257.484.087.702-.171.217-.483.256-.702.087-.164-.128-.335-.249-.51-.361-.275-.175-.64-.117-.849.135Zm10.627,5.337l-3.461-3.59v-3.995h.5c.276,0,.5-.224.5-.5s-.224-.5-.5-.5h-4c-.276,0-.5.224-.5.5s.224.5.5.5h.5v3.996l-3.534,3.673c-.505.615-.607,1.445-.268,2.165.341.719,1.046,1.166,1.842,1.166h6.92c.816,0,1.529-.464,1.861-1.209.332-.747.198-1.588-.36-2.206Zm-.554,1.8c-.171.385-.525.615-.947.615h-6.92c-.411,0-.762-.222-.938-.594s-.125-.784.11-1.073l3.647-3.789c.09-.093.14-.218.14-.347v-4.197h1v4.197c0,.13.05.254.14.347l3.59,3.723c.283.313.35.731.178,1.118Z"
        fill={color}
      />
    </svg>
  );
};
