interface IconProps {
  color?: string;
}

export const Flask: React.FC<IconProps> = ({ color = "darkBlue" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
      <path
        d="m18.029,10.135c-.664-.655-1.029-1.535-1.029-2.479V1h1.5c.276,0,.5-.224.5-.5s-.224-.5-.5-.5H5.5c-.276,0-.5.224-.5.5s.224.5.5.5h1.5v6.657c0,.943-.365,1.824-1.029,2.479C3.74,12.333,0,16.565,0,20.214c0,.792.211,1.512.626,2.14.68,1.03,1.88,1.646,3.211,1.646h16.326c1.331,0,2.531-.615,3.211-1.645.415-.628.626-1.349.626-2.141,0-3.649-3.74-7.881-5.971-10.079Zm4.511,11.668c-.494.749-1.383,1.196-2.377,1.196H3.837c-.994,0-1.883-.447-2.377-1.197-.306-.461-.46-.996-.46-1.589,0-1.341.615-2.817,1.464-4.216.012,0,.024.002.036.002h16c.276,0,.5-.224.5-.5s-.224-.5-.5-.5H3.115c1.222-1.754,2.685-3.293,3.557-4.153.856-.844,1.328-1.977,1.328-3.19V1h8v6.657c0,1.213.472,2.347,1.328,3.19,1.705,1.682,5.672,5.96,5.672,9.367,0,.593-.154,1.127-.46,1.589Z"
        fill={color}
      />
    </svg>
  );
};
