export const ZipFileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="512"
      height="512"
    >
      <path d="m7,3.5v-1c0-.276.224-.5.5-.5s.5.224.5.5v1c0,.276-.224.5-.5.5s-.5-.224-.5-.5Zm.5,3.5c.276,0,.5-.224.5-.5v-1c0-.276-.224-.5-.5-.5s-.5.224-.5.5v1c0,.276.224.5.5.5Zm0,3c.276,0,.5-.224.5-.5v-1c0-.276-.224-.5-.5-.5s-.5.224-.5.5v1c0,.276.224.5.5.5Zm14.5-.015v9.515c0,2.481-2.019,4.5-4.5,4.5H6.5c-2.481,0-4.5-2.019-4.5-4.5V4.5C2,2.019,4.019,0,6.5,0h5.515c1.735,0,3.368.676,4.597,1.904l3.484,3.485c1.228,1.227,1.904,2.859,1.904,4.596Zm-6.096-7.375c-.552-.551-1.199-.97-1.904-1.243v5.132c0,.827.673,1.5,1.5,1.5h5.132c-.273-.706-.693-1.353-1.244-1.904l-3.484-3.485Zm5.096,7.375c0-.334-.03-.663-.088-.985h-5.412c-1.379,0-2.5-1.122-2.5-2.5V1.088c-.322-.058-.651-.088-.985-.088h-5.515c-1.93,0-3.5,1.57-3.5,3.5v15c0,1.93,1.57,3.5,3.5,3.5h11c1.93,0,3.5-1.57,3.5-3.5v-9.515Zm-11.449,5.177c.122.435.038.891-.231,1.254-.276.371-.699.583-1.162.583h-1.314c-.463,0-.886-.213-1.162-.583-.275-.371-.356-.837-.224-1.279l.596-2.979c.2-.672.75-1.108,1.411-1.156.733.048,1.283.484,1.472,1.111l.615,3.05Zm-.965.263l-.012-.045-.607-3.025c-.057-.181-.259-.338-.503-.356-.172.018-.374.176-.442.402l-.596,2.979c-.053.182-.027.326.058.44s.217.18.359.18h1.314c.143,0,.274-.066.359-.18s.11-.258.069-.395Z" />
    </svg>
  );
};
