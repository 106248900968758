import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import api from "api";
import { useToaster } from "context/TosterContext";

import {
  createFacilityMonitoring,
  deleteFacilityMonitoring,
  updateFacilityMonitoring,
} from "helpers/facilityMonitoring";

import { Button } from "ui/base/Button";
import { ModalWarning } from "ui/base/ModalWarning";
import {
  FormRow,
  FormCell,
  FormTable,
  FormHeader,
  FormInput,
  FormLabel,
  FormSelectLabel,
} from "./MonthlyVisualObservationStyled";

import {
  initialMainObservation,
  initialObservationTab,
  MainObservation,
  ObservationTab,
  SampleObservationFormProps,
} from "../types";

interface RootState {
  user: {
    id: number;
  } | null;
}

interface RadioGroupProps {
  name: string;
  value: boolean | null;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  required?: boolean;
}

const RadioGroup: React.FC<RadioGroupProps> = React.memo(
  ({ name, value, onChange, disabled = false, required = false }) => (
    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
      <FormSelectLabel>
        <input
          type="radio"
          name={name}
          value="true"
          checked={value === true}
          onChange={() => onChange(true)}
          disabled={disabled}
          required={required}
        />
        Yes
      </FormSelectLabel>
      <FormSelectLabel>
        <input
          type="radio"
          name={name}
          value="false"
          checked={value === false}
          onChange={() => onChange(false)}
          disabled={disabled}
          required={required}
        />
        No
      </FormSelectLabel>
    </div>
  )
);

interface ReadOnlyFieldProps {
  label: string;
  value: string | boolean | null | undefined;
}

const ReadOnlyField: React.FC<ReadOnlyFieldProps> = React.memo(({ label, value }) => (
  <FormCell style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
    <FormLabel>{label}</FormLabel>
    <span>{value !== null && value !== undefined ? String(value) : <em>N/A</em>}</span>
  </FormCell>
));

interface ObservationTabProps {
  tab: ObservationTab;
  index: number;
  isReadOnly: boolean;
  errors: { [key: string]: string };
  onChange: (index: number, field: keyof ObservationTab, value: any) => void;
  onDelete: (index: number) => void;
  fieldRefs: React.MutableRefObject<{ [key: string]: HTMLInputElement | null }>;
}

const ObservationTabComponent: React.FC<ObservationTabProps> = React.memo(
  ({ tab, index, isReadOnly, errors, onChange, onDelete, fieldRefs }) => {
    const handleFieldChange = (field: keyof ObservationTab, value: any) => {
      onChange(index, field, value);
    };
    const isNotesEnabled = useMemo(() => {
      return (
        tab.water_discharge || tab.trash || tab.floating_material || tab.oil_sheen || tab.discoloration || tab.odors
      );
    }, [tab.water_discharge, tab.trash, tab.floating_material, tab.oil_sheen, tab.discoloration, tab.odors]);

    return (
      <FormTable key={tab.id || index}>
        <FormHeader>
          <FormCell style={{ display: "flex", justifyContent: "center" }}>
            Observation Details {isReadOnly ? `(Page ${index + 1})` : ""}
          </FormCell>
        </FormHeader>
        <FormRow>
          <FormCell>
            <FormLabel htmlFor={`outfall_${index}`}>Outfall</FormLabel>
            {isReadOnly ? (
              <span>{tab.outfall || <em>N/A</em>}</span>
            ) : (
              <>
                <FormInput
                  id={`outfall_${index}`}
                  value={tab.outfall}
                  onChange={(e) => handleFieldChange("outfall", e.target.value)}
                  required
                  ref={(el) => (fieldRefs.current[`outfall_${index}`] = el)}
                />
                {errors[`outfall_${index}`] && (
                  <span style={{ color: "red", fontSize: "12px" }}>{errors[`outfall_${index}`]}</span>
                )}
              </>
            )}
          </FormCell>
          <FormCell>
            <FormLabel htmlFor={`observation_${index}`}>Observation (date/time)</FormLabel>
            {isReadOnly ? (
              <span>{tab.observation || <em>N/A</em>}</span>
            ) : (
              <>
                <FormInput
                  id={`observation_${index}`}
                  type="datetime-local"
                  value={tab.observation}
                  onChange={(e) => handleFieldChange("observation", e.target.value)}
                  required
                  ref={(el) => (fieldRefs.current[`observation_${index}`] = el)}
                />
                {errors[`observation_${index}`] && (
                  <span style={{ color: "red", fontSize: "12px" }}>{errors[`observation_${index}`]}</span>
                )}
              </>
            )}
          </FormCell>
          <FormCell>
            <FormLabel htmlFor={`ph_${index}`}>Field pH</FormLabel>
            {isReadOnly ? (
              <span>{tab.ph || <em>N/A</em>}</span>
            ) : (
              <>
                <FormInput
                  id={`ph_${index}`}
                  value={tab.ph}
                  onChange={(e) => handleFieldChange("ph", e.target.value)}
                  required
                  ref={(el) => (fieldRefs.current[`ph_${index}`] = el)}
                />
                {errors[`ph_${index}`] && (
                  <span style={{ color: "red", fontSize: "12px" }}>{errors[`ph_${index}`]}</span>
                )}
              </>
            )}
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <FormLabel>Non-Storm Water Discharge?</FormLabel>
            {isReadOnly ? (
              <span>{tab.water_discharge ? "Yes" : "No"}</span>
            ) : (
              <RadioGroup
                name={`water_discharge_${index}`}
                value={tab.water_discharge}
                onChange={(val) => handleFieldChange("water_discharge", val)}
                required
              />
            )}
            {!isReadOnly && errors[`water_discharge_${index}`] && (
              <span style={{ color: "red", fontSize: "12px" }}>{errors[`water_discharge_${index}`]}</span>
            )}
          </FormCell>
          <FormCell>
            <FormLabel>Trash or Debris?</FormLabel>
            {isReadOnly ? (
              <span>{tab.trash ? "Yes" : "No"}</span>
            ) : (
              <RadioGroup
                name={`trash_${index}`}
                value={tab.trash}
                onChange={(val) => handleFieldChange("trash", val)}
                required
              />
            )}
            {!isReadOnly && errors[`trash_${index}`] && (
              <span style={{ color: "red", fontSize: "12px" }}>{errors[`trash_${index}`]}</span>
            )}
          </FormCell>
          <FormCell>
            <FormLabel>Floating Material?</FormLabel>
            {isReadOnly ? (
              <span>{tab.floating_material ? "Yes" : "No"}</span>
            ) : (
              <RadioGroup
                name={`floating_material_${index}`}
                value={tab.floating_material}
                onChange={(val) => handleFieldChange("floating_material", val)}
                required
              />
            )}
            {!isReadOnly && errors[`floating_material_${index}`] && (
              <span style={{ color: "red", fontSize: "12px" }}>{errors[`floating_material_${index}`]}</span>
            )}
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <FormLabel>Oil and Grease or sheen?</FormLabel>
            {isReadOnly ? (
              <span>{tab.oil_sheen ? "Yes" : "No"}</span>
            ) : (
              <RadioGroup
                name={`oil_sheen_${index}`}
                value={tab.oil_sheen}
                onChange={(val) => handleFieldChange("oil_sheen", val)}
                required
              />
            )}
            {!isReadOnly && errors[`oil_sheen_${index}`] && (
              <span style={{ color: "red", fontSize: "12px" }}>{errors[`oil_sheen_${index}`]}</span>
            )}
          </FormCell>
          <FormCell>
            <FormLabel>Discolorations or Turbidity?</FormLabel>
            {isReadOnly ? (
              <span>{tab.discoloration ? "Yes" : "No"}</span>
            ) : (
              <RadioGroup
                name={`discoloration_${index}`}
                value={tab.discoloration}
                onChange={(val) => handleFieldChange("discoloration", val)}
                required
              />
            )}
            {!isReadOnly && errors[`discoloration_${index}`] && (
              <span style={{ color: "red", fontSize: "12px" }}>{errors[`discoloration_${index}`]}</span>
            )}
          </FormCell>
          <FormCell>
            <FormLabel>Odors?</FormLabel>
            {isReadOnly ? (
              <span>{tab.odors ? "Yes" : "No"}</span>
            ) : (
              <RadioGroup
                name={`odors_${index}`}
                value={tab.odors}
                onChange={(val) => handleFieldChange("odors", val)}
                required
              />
            )}
            {!isReadOnly && errors[`odors_${index}`] && (
              <span style={{ color: "red", fontSize: "12px" }}>{errors[`odors_${index}`]}</span>
            )}
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <FormLabel htmlFor={`notes_${index}`}>Notes (Describe and include source)</FormLabel>
            {isReadOnly ? (
              <span>{tab.notes || <em>N/A</em>}</span>
            ) : (
              <>
                <FormInput
                  id={`notes_${index}`}
                  value={tab.notes}
                  onChange={(e) => handleFieldChange("notes", e.target.value)}
                  required
                  disabled={!isNotesEnabled}
                  ref={(el) => (fieldRefs.current[`notes_${index}`] = el)}
                />
                {isNotesEnabled && errors[`notes_${index}`] && (
                  <span style={{ color: "red", fontSize: "12px" }}>{errors[`notes_${index}`]}</span>
                )}
              </>
            )}
          </FormCell>
        </FormRow>
        <FormRow>
          <FormCell>
            <FormLabel htmlFor={`describe_wsppp_${index}`}>Describe SWPPP Revisions</FormLabel>
            {isReadOnly ? (
              <span>{tab.describe_wsppp || <em>N/A</em>}</span>
            ) : (
              <>
                <FormInput
                  id={`describe_wsppp_${index}`}
                  value={tab.describe_wsppp}
                  onChange={(e) => handleFieldChange("describe_wsppp", e.target.value)}
                  required
                  ref={(el) => (fieldRefs.current[`describe_wsppp_${index}`] = el)}
                />
                {errors[`describe_wsppp_${index}`] && (
                  <span style={{ color: "red", fontSize: "12px" }}>{errors[`describe_wsppp_${index}`]}</span>
                )}
              </>
            )}
          </FormCell>
        </FormRow>
      </FormTable>
    );
  }
);

const SampleObservationForm: React.FC<SampleObservationFormProps> = ({ isReadOnly, formData, wdid }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { addToast } = useToaster();
  const user = useSelector((state: RootState) => state.user);

  const [mainObservation, setMainObservation] = useState<MainObservation>(initialMainObservation);
  const [observationTabs, setObservationTabs] = useState<ObservationTab[]>([{ ...initialObservationTab }]);
  const [selectedMonthIndex, setSelectedMonthIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const monthNames = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const fieldRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  useEffect(() => {
    setMainObservation(formData || initialMainObservation);
  }, [formData]);

  useEffect(() => {
    const fetchObservationTabs = async () => {
      if (formData?.id) {
        try {
          const res = await api.get(`event_observations_items?event_observations_id=eq.${formData.id}`);
          if (Array.isArray(res.data) && res.data.length > 0) {
            setObservationTabs(res.data);
          }
        } catch (error) {
          console.error("Error fetching observation tabs:", error);
          addToast("Error fetching observation tabs", "error");
        }
      } else {
        setObservationTabs([{ ...initialObservationTab }]);
      }
    };
    fetchObservationTabs();
  }, [formData?.id, addToast]);

  const handleChangeMain = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const { name, value, type } = e.target;
      setMainObservation((prev) => ({
        ...prev,
        [name]: type === "radio" ? value === "true" : value,
      }));
    },
    []
  );

  const handleChangeObservationTab = useCallback((index: number, field: keyof ObservationTab, value: any) => {
    setObservationTabs((prevTabs) => prevTabs.map((tab, idx) => (idx === index ? { ...tab, [field]: value } : tab)));
  }, []);

  const validate = useCallback((): { isValid: boolean; errors: { [key: string]: string } } => {
    const newErrors: { [key: string]: string } = {};

    if (!mainObservation.sample_date) {
      newErrors.sample_date = "Sample date is required.";
    }
    if (!mainObservation.title.trim()) {
      newErrors.title = "Title is required.";
    }

    observationTabs.forEach((tab, index) => {
      const isNotesEnabled =
        tab.water_discharge || tab.trash || tab.floating_material || tab.oil_sheen || tab.discoloration || tab.odors;

      if (isNotesEnabled && !tab.notes.trim()) {
        newErrors[`notes_${index}`] = "Notes are required.";
      }
      if (!tab.describe_wsppp.trim()) {
        newErrors[`describe_wsppp_${index}`] = "Describe SWPPP Revisions are required.";
      }
      ["water_discharge", "trash", "floating_material", "oil_sheen", "discoloration", "odors"].forEach((field) => {
        if (tab[field as keyof ObservationTab] === null) {
          newErrors[`${field}_${index}`] = `${field.replace(/_/g, " ")} selection is required.`;
        }
      });
    });

    const isValid = Object.keys(newErrors).length === 0;

    return { isValid, errors: newErrors };
  }, [mainObservation, observationTabs]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validation = validate();

      if (!validation.isValid) {
        setErrors(validation.errors);
        addToast("Please fix the errors in the form.", "error");

        const tabIndicesWithErrors: number[] = [];
        Object.keys(validation.errors).forEach((key) => {
          const match = key.match(/^(\w+)_(\d+)$/);
          if (match) {
            const tabIndex = parseInt(match[2], 10);
            if (!tabIndicesWithErrors.includes(tabIndex)) {
              tabIndicesWithErrors.push(tabIndex);
            }
          }
        });

        if (tabIndicesWithErrors.length > 0) {
          const firstErrorTab = tabIndicesWithErrors[0];
          setActiveTab(firstErrorTab);

          setTimeout(() => {
            const firstErrorField = Object.keys(validation.errors).find((key) => {
              const match = key.match(/^(\w+)_(\d+)$/);
              return match && parseInt(match[2], 10) === firstErrorTab;
            });

            if (firstErrorField) {
              fieldRefs.current[firstErrorField]?.focus();
            }
          }, 100);
        }

        return;
      }

      try {
        const mainPayload = {
          ...mainObservation,
          user_id: user?.id,
          wdid: wdid || "",
          month: monthNames[selectedMonthIndex],
          data_time: mainObservation.sample_date,
          reporting_year: new Date().getFullYear().toString(),
          status: mainObservation.status,
        };

        let eventObservationId: number;

        if (!mainObservation.id) {
          const { id, ...payloadWithoutId } = mainPayload;
          const created = await createFacilityMonitoring("event_observations", payloadWithoutId);
          if (Array.isArray(created) && created.length > 0 && created[0].id) {
            eventObservationId = created[0].id;
            setMainObservation((prev) => ({ ...prev, id: eventObservationId }));
          } else if (created?.data?.[0]?.id) {
            eventObservationId = created.data[0].id;
            setMainObservation((prev) => ({ ...prev, id: eventObservationId }));
          } else {
            throw new Error("Can't upload id for event_observations.");
          }
        } else {
          await updateFacilityMonitoring(mainObservation.id, "event_observations", mainPayload);
          eventObservationId = mainObservation.id;
        }

        await Promise.all(
          observationTabs.map(async (tab, index) => {
            const tabPayload = {
              ...tab,
              event_observations_id: eventObservationId,
              active_tab: observationTabs.length,
            };

            if (tab.id) {
              return updateFacilityMonitoring(tab.id, "event_observations_items", tabPayload);
            } else {
              return createFacilityMonitoring("event_observations_items", tabPayload);
            }
          })
        );

        addToast("Form updated successfully!", "success");
        setTimeout(() => {
          window.location.href = "/monitoring";
        }, 2000);
      } catch (error: any) {
        console.error("Error saving data:", error);
        addToast("Error saving data", "error");
      }
    },
    [mainObservation, observationTabs, user?.id, wdid, monthNames, validate, addToast, selectedMonthIndex]
  );

  const deleteObservationTab = useCallback(
    async (index: number) => {
      const tabToDelete = observationTabs[index];
      if (tabToDelete?.id) {
        try {
          await deleteFacilityMonitoring(tabToDelete.id, "event_observations_items");
          addToast("Observation tab deleted successfully!", "success");
        } catch (error) {
          console.error("Error deleting observation tab:", error);
          addToast("Error deleting observation tab", "error");
          return;
        }
      }

      setObservationTabs((prevTabs) => prevTabs.filter((_, idx) => idx !== index));
      setActiveTab((prev) => (prev === index ? 0 : prev > index ? prev - 1 : prev));
    },
    [observationTabs, addToast]
  );

  const addNewObservationTab = useCallback(() => {
    setObservationTabs((prevTabs) => [...prevTabs, { ...initialObservationTab }]);
    setActiveTab(observationTabs.length);
  }, [observationTabs.length]);

  const handleDeleteClick = useCallback(() => {
    if (!mainObservation.id) {
      addToast("No 'id' found. Could not delete record!", "error");
      return;
    }
    setIsOpen(true);
  }, [mainObservation.id, addToast]);

  const handleConfirmDelete = useCallback(
    async (confirmed: boolean) => {
      setIsOpen(false);
      if (confirmed && mainObservation.id) {
        try {
          const res = await api.get(`event_observations_items?event_observations_id=eq.${mainObservation.id}`);
          const itemsToDelete = res.data;
          await Promise.all(
            itemsToDelete.map((item: any) => deleteFacilityMonitoring(item.id, "event_observations_items"))
          );
          await deleteFacilityMonitoring(mainObservation.id, "event_observations");
          addToast("Form deleted successfully!", "success");
          setTimeout(() => {
            window.location.href = "/monitoring";
          }, 2000);
        } catch (error) {
          console.error("Error deleting form:", error);
          addToast("Error deleting form!", "error");
        }
      }
    },
    [mainObservation.id, addToast]
  );

  const handleCancelDelete = useCallback(() => {
    setIsOpen(false);
  }, []);

  const reactToPrintContent = useCallback(() => componentRef.current, []);

  const renderObservationTab = useCallback(
    (tab: ObservationTab, index: number) => (
      <ObservationTabComponent
        key={tab.id || index}
        tab={tab}
        index={index}
        isReadOnly={isReadOnly}
        errors={errors}
        onChange={handleChangeObservationTab}
        onDelete={deleteObservationTab}
        fieldRefs={fieldRefs}
      />
    ),
    [isReadOnly, errors, handleChangeObservationTab, deleteObservationTab]
  );

  return (
    <div
      style={{
        display: "flex",
        width: "80%",
        margin: "40px auto",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <ModalWarning isOpen={isOpen} onConfirm={handleConfirmDelete} onCancel={handleCancelDelete} />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="white" type="button" background="darkBlue">
          <a href="/monitoring" style={{ color: "inherit", textDecoration: "none" }}>
            Go back
          </a>
        </Button>
      </div>

      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap", justifyContent: "flex-end" }}
        >
          {isReadOnly ? (
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle="Monitoring Form"
              removeAfterPrint
              trigger={() => (
                <Button type="button" color="white" background="blue">
                  Print completed form
                </Button>
              )}
            />
          ) : (
            <div style={{ display: "flex", gap: "10px" }}>
              {!mainObservation.url && (
                <Button type="submit" color="white" background="blue">
                  Save Form
                </Button>
              )}
              <Button type="button" color="white" onClick={handleDeleteClick}>
                Delete Form
              </Button>
              <Button type="button" color="white" background="dark">
                <a
                  href={
                    mainObservation.id
                      ? `/sample-facility-form-update?id=${mainObservation.id}&wdid=${wdid}`
                      : `/sample-facility-form-update?&wdid=${wdid}`
                  }
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Upload Form
                </a>
              </Button>
            </div>
          )}
        </div>

        <div
          ref={componentRef}
          id="printable-section"
          style={{
            width: !isReadOnly ? "100%" : isReadOnly && !mainObservation.url ? "100%" : "auto",
            margin: "20px auto",
            pointerEvents: isReadOnly ? "none" : "auto",
            opacity: isReadOnly ? 0.9 : 1,
          }}
        >
          {mainObservation.url ? (
            <img src={mainObservation.url} alt="Sample-Observation-Form" style={{ width: "100%", height: "auto" }} />
          ) : (
            <FormTable style={{ width: "100%" }}>
              <FormTable>
                <FormHeader>
                  <FormCell style={{ display: "flex", justifyContent: "center" }}>General Information</FormCell>
                </FormHeader>
                <FormRow
                  style={{
                    width: "100%",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {isReadOnly ? (
                    <ReadOnlyField label="Month" value={mainObservation.month} />
                  ) : (
                    monthNames.map((month, idx) => (
                      <p
                        key={month}
                        style={
                          idx === selectedMonthIndex
                            ? { fontWeight: "bold", textDecoration: "underline", cursor: "default" }
                            : { cursor: "pointer" }
                        }
                        onClick={() => setSelectedMonthIndex(idx)}
                      >
                        {month}
                      </p>
                    ))
                  )}
                  {!isReadOnly && errors.month && (
                    <span style={{ color: "red", fontSize: "12px" }}>{errors.month}</span>
                  )}
                </FormRow>
                <FormRow>
                  <FormCell>
                    <FormLabel htmlFor="facility">Facility</FormLabel>
                    {isReadOnly ? (
                      <span>{mainObservation.wdid || <em>N/A</em>}</span>
                    ) : (
                      <>
                        <span>{wdid}</span>
                        {errors.wdid && <span style={{ color: "red", fontSize: "12px" }}>{errors.wdid}</span>}
                      </>
                    )}
                  </FormCell>
                  <FormCell>
                    <FormLabel htmlFor="sample_date">Date</FormLabel>
                    {isReadOnly ? (
                      <span>{mainObservation.sample_date || <em>N/A</em>}</span>
                    ) : (
                      <>
                        <FormInput
                          type="date"
                          name="sample_date"
                          id="sample_date"
                          value={mainObservation.sample_date}
                          onChange={handleChangeMain}
                          required
                          ref={(el) => (fieldRefs.current["sample_date"] = el)}
                        />
                        {errors.sample_date && (
                          <span style={{ color: "red", fontSize: "12px" }}>{errors.sample_date}</span>
                        )}
                      </>
                    )}
                  </FormCell>
                  <FormCell>
                    <FormLabel htmlFor="title">Observer Name and Title</FormLabel>
                    {isReadOnly ? (
                      <span>{mainObservation.title || <em>N/A</em>}</span>
                    ) : (
                      <>
                        <FormInput
                          name="title"
                          id="title"
                          value={mainObservation.title}
                          onChange={handleChangeMain}
                          required
                          ref={(el) => (fieldRefs.current["title"] = el)}
                        />
                        {errors.title && <span style={{ color: "red", fontSize: "12px" }}>{errors.title}</span>}
                      </>
                    )}
                  </FormCell>
                </FormRow>
                <FormRow>
                  <FormLabel style={{ padding: "10px" }}>
                    Confirm the following criteria for a qualifying storm event are met:
                  </FormLabel>
                </FormRow>
                <FormRow>
                  <FormCell>
                    <FormLabel htmlFor="at_least_one_drainage_area">
                      Discharge occurred from at least one drainage area?
                    </FormLabel>
                  </FormCell>
                  <FormCell>
                    {isReadOnly ? (
                      <span>{mainObservation.at_least_one_drainage_area ? "Yes" : "No"}</span>
                    ) : (
                      <RadioGroup
                        name="at_least_one_drainage_area"
                        value={mainObservation.at_least_one_drainage_area}
                        onChange={(val) =>
                          setMainObservation((prev) => ({
                            ...prev,
                            at_least_one_drainage_area: val,
                          }))
                        }
                        required
                      />
                    )}
                    {!isReadOnly && errors.at_least_one_drainage_area && (
                      <span style={{ color: "red", fontSize: "12px" }}>{errors.at_least_one_drainage_area}</span>
                    )}
                  </FormCell>
                </FormRow>
                <FormRow>
                  <FormCell>
                    <FormLabel htmlFor="preceded">
                      Preceded by 48 hours with no discharge from any drainage area?
                    </FormLabel>
                  </FormCell>
                  <FormCell>
                    {isReadOnly ? (
                      <span>{mainObservation.preceded ? "Yes" : "No"}</span>
                    ) : (
                      <RadioGroup
                        name="preceded"
                        value={mainObservation.preceded}
                        onChange={(val) =>
                          setMainObservation((prev) => ({
                            ...prev,
                            preceded: val,
                          }))
                        }
                        required
                      />
                    )}
                    {!isReadOnly && errors.preceded && (
                      <span style={{ color: "red", fontSize: "12px" }}>{errors.preceded}</span>
                    )}
                  </FormCell>
                </FormRow>
                <FormRow>
                  <FormCell>
                    <FormLabel>
                      Samples were collected within four (4) hours of:
                      <br />
                      A. the start of discharge;
                      <br /> B. the start of facility operations if the event occurs within the previous 12-hour period.
                    </FormLabel>
                  </FormCell>
                  <FormCell>
                    {isReadOnly ? (
                      <span>{mainObservation.samples_a ? "Yes" : "No"}</span>
                    ) : (
                      <RadioGroup
                        name="samples_a"
                        value={mainObservation.samples_a}
                        onChange={(val) =>
                          setMainObservation((prev) => ({
                            ...prev,
                            samples_a: val,
                          }))
                        }
                        required
                      />
                    )}
                    {!isReadOnly && errors.samples_a && (
                      <span style={{ color: "red", fontSize: "12px" }}>{errors.samples_a}</span>
                    )}
                  </FormCell>
                </FormRow>
              </FormTable>

              {isReadOnly ? (
                observationTabs.map((tab, index) => renderObservationTab(tab, index))
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      margin: "20px 0",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {observationTabs.map((_, index) => {
                      return (
                        <Button
                          key={index}
                          type="button"
                          color="white"
                          background={activeTab === index ? "darkBlue" : "blue"}
                          onClick={() => setActiveTab(index)}
                        >
                          Observation {index + 1}
                        </Button>
                      );
                    })}
                    <Button type="button" background="blue" color="white" onClick={addNewObservationTab}>
                      Add Observation
                    </Button>
                    {observationTabs.length > 1 && (
                      <Button
                        type="button"
                        color="white"
                        background="grey"
                        onClick={() => deleteObservationTab(activeTab)}
                      >
                        Delete Observation
                      </Button>
                    )}
                  </div>
                  {observationTabs[activeTab] && renderObservationTab(observationTabs[activeTab], activeTab)}
                </>
              )}
            </FormTable>
          )}
        </div>
      </form>
    </div>
  );
};

export default SampleObservationForm;
