interface IconProps {
  color?: string;
}

export const Folder: React.FC<IconProps> = ({ color = "darkBlue" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
      <path
        d="m21,11v-5.5c0-1.378-1.121-2.5-2.5-2.5h-8.382L6.118,1h-3.618c-1.379,0-2.5,1.122-2.5,2.5v19.5h19.857l4.098-12h-2.955ZM1,22V3.5c0-.827.673-1.5,1.5-1.5h3.382s4,2,4,2h8.618c.827,0,1.5.673,1.5,1.5v5.5H5.617s-3.483,11-3.483,11h-1.134Zm18.143,0H3.183l3.167-10h16.207s-3.414,10-3.414,10Z"
        fill={color}
      />
    </svg>
  );
};
