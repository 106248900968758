import { ReactNode } from "react";
import { Profile, Home, Info, Account, WaterQuality, Monitoring, Resources, ContactUs } from "./pages";

import RedirectPage from "pages/RedirectPage";
import MounthlyObservationForm from "pages/MonthlyObservation/MonthlyObservationForm";
import MounthlyObservationFormUpdate from "pages/MonthlyObservation/MonthlyObservationFormUpdate";
import AnnualFacilityFormPage from "pages/AnnualFacility/AnnualObservationForm";
import SampleObservationFormPage from "pages/SampleObservationForm/SampleObservationFormPage";
import SampleObservationFormUpdate from "pages/SampleObservationForm/SampleObservationFormUpdate";
import AnnualObservationFormUpdate from "pages/AnnualFacility/AnnualObservationFormUpdate";

export const routesPath = {
  profile: "/profile",
  home: "/",
  info: "/info",
  mounthlyObservationForm: "/monthly-observation-form",
  mounthlyObservationFormUpdate: "/monthly-observation-form-update",
  annualFacilityForm: "/annual-facility-form",
  annualFacilityUpdate: "/annual-facility-form-update",
  sampleFacilityForm: "/sample-facility-form",
  sampleFacilityFormUpdate: "/sample-facility-form-update",
  monitoring: "/monitoring",
  waterQuality: "/water-quality",
  resources: "/resources",
  account: "/profile",
  contactUs: "/contact-us",
  redirect: "/redirect",
  redirect_to_login: "/redirect_to_login",
  logout: "/logout",
};

type RouteType = {
  path: string;
  component: ReactNode;
};

export const routes: RouteType[] = [
  {
    path: routesPath.redirect,
    component: <RedirectPage props={"/"} />,
  },
  {
    path: routesPath.profile,
    component: <Profile />,
  },
  {
    path: routesPath.home,
    component: <Home />,
  },
  {
    path: routesPath.info,
    component: <Info />,
  },
  {
    path: routesPath.mounthlyObservationForm,
    component: <MounthlyObservationForm />,
  },
  {
    path: routesPath.mounthlyObservationFormUpdate,
    component: <MounthlyObservationFormUpdate />,
  },
  {
    path: routesPath.annualFacilityForm,
    component: <AnnualFacilityFormPage />,
  },
  {
    path: routesPath.annualFacilityUpdate,
    component: <AnnualObservationFormUpdate />,
  },
  {
    path: routesPath.sampleFacilityForm,
    component: <SampleObservationFormPage />,
  },
  {
    path: routesPath.sampleFacilityFormUpdate,
    component: <SampleObservationFormUpdate />,
  },
  {
    path: routesPath.monitoring,
    component: <Monitoring />,
  },
  {
    path: routesPath.account,
    component: <Account />,
  },
  {
    path: routesPath.waterQuality,
    component: <WaterQuality />,
  },
  {
    path: routesPath.resources,
    component: <Resources />,
  },
  {
    path: routesPath.contactUs,
    component: <ContactUs />,
  },
];
