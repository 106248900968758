import styled from "styled-components";
import { themeColor } from "ui/theme";

export const MonitoringViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 52px;
`;

export const MonitotingViewTable = styled.div<{ wide?: boolean; margin?: string }>`
  width: ${({ wide }) => (wide ? "100%" : "calc(50% - 23px)")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  overflow-x: auto;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const MonitoringRow = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 24px) {
    width: 100%;
  }
`;

export const MonitoringCol = styled.div`
  width: calc(50% - 23px);
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Text = styled.p`
    margin 14px 0;
     width: 100%;
`;

export const Paragraph = styled.p`
    padding: 20px ;
    margin 14px 0;
    background-color: #fefefe;
    width: 100%;
`;
export const HomeCol = styled.div`
    margin 14px 0;
    width: 100%;
`;

export const MonitoringForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background-color: #e4f0f9;
  }
  &:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 4px;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${themeColor("primary")};
  }
`;

export const CheckboxWrapper = styled.div`
  input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  input:checked + ${CheckboxLabel} {
    &:after {
      opacity: 1;
    }
  }
`;
