interface IconProps {
  color?: string;
}

export const SWPPPDocument: React.FC<IconProps> = ({ color = "darkBlue" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
      <path
        d="m7,13h5v1h-5v-1Zm10-4H7v1h10v-1Zm0-4H7v1h10v-1Zm4,12.942v5.998l-2.5-1.683-2.5,1.683v-5.998c-.617-.631-1-1.492-1-2.442,0-1.93,1.57-3.5,3.5-3.5.539,0,1.044.133,1.5.351V2.5c0-.827-.673-1.5-1.5-1.5H5.5c-.827,0-1.5.673-1.5,1.5v18.5h10v1H3V2.5c0-1.378,1.121-2.5,2.5-2.5h13c1.379,0,2.5,1.122,2.5,2.5v10.558c.617.631,1,1.492,1,2.442s-.383,1.81-1,2.442Zm-5-2.442c0,1.378,1.121,2.5,2.5,2.5s2.5-1.122,2.5-2.5-1.121-2.5-2.5-2.5-2.5,1.122-2.5,2.5Zm4,6.561v-3.412c-.456.219-.961.351-1.5.351s-1.044-.133-1.5-.351v3.412l1.5-1.009,1.5,1.009Z"
        fill={color}
      />
    </svg>
  );
};
