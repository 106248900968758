import React from "react";

interface IconProps {
  color?: string;
}

export const GroupLeaderInspectionNotes: React.FC<IconProps> = ({ color = "darkBlue" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M12,7c1.378,0,2.5-1.122,2.5-2.5s-1.122-2.5-2.5-2.5-2.5,1.122-2.5,2.5,1.122,2.5,2.5,2.5Zm0-4c.827,0,1.5,.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5,.673-1.5,1.5-1.5Zm.5,5h-1c-1.93,0-3.5,1.57-3.5,3.5v3c0,1.391,.822,2.585,2,3.149v5.851c0,.276,.224,.5,.5,.5s.5-.224,.5-.5v-5.551c.165,.024,.329,.051,.5,.051h1c.171,0,.335-.027,.5-.051v5.551c0,.276,.224,.5,.5,.5s.5-.224,.5-.5v-5.851c1.178-.564,2-1.758,2-3.149v-3c0-1.93-1.57-3.5-3.5-3.5Zm2.5,6.5c0,1.378-1.122,2.5-2.5,2.5h-1c-1.378,0-2.5-1.122-2.5-2.5v-3c0-1.378,1.122-2.5,2.5-2.5h1c1.378,0,2.5,1.122,2.5,2.5v3Zm5-9.5c1.378,0,2.5-1.122,2.5-2.5s-1.122-2.5-2.5-2.5-2.5,1.122-2.5,2.5,1.122,2.5,2.5,2.5Zm0-4c.827,0,1.5,.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5,.673-1.5,1.5-1.5Zm4,8.5v3c0,1.391-.822,2.585-2,3.149v5.851c0,.276-.224,.5-.5,.5s-.5-.224-.5-.5v-5.551c-.165,.024-.329,.051-.5,.051h-1.5v5.5c0,.276-.224,.5-.5,.5s-.5-.224-.5-.5v-6c0-.276,.224-.5,.5-.5h2c1.378,0,2.5-1.122,2.5-2.5v-3c0-1.378-1.122-2.5-2.5-2.5h-1c-.84,0-1.619,.418-2.083,1.118-.153,.229-.463,.292-.693,.14-.23-.153-.292-.463-.14-.693,.651-.979,1.741-1.564,2.917-1.564h1c1.93,0,3.5,1.57,3.5,3.5ZM4,5c1.378,0,2.5-1.122,2.5-2.5S5.378,0,4,0,1.5,1.122,1.5,2.5s1.122,2.5,2.5,2.5Zm0-4c.827,0,1.5,.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5,.673-1.5,1.5-1.5Zm3.417,6.564c.153,.23,.09,.541-.14,.693-.231,.152-.541,.09-.693-.14-.465-.7-1.244-1.118-2.083-1.118h-1c-1.378,0-2.5,1.122-2.5,2.5v3c0,1.378,1.122,2.5,2.5,2.5h2c.276,0,.5,.224,.5,.5v6c0,.276-.224,.5-.5,.5s-.5-.224-.5-.5v-5.5h-1.5c-.171,0-.335-.027-.5-.051v5.551c0,.276-.224,.5-.5,.5s-.5-.224-.5-.5v-5.851c-1.178-.564-2-1.758-2-3.149v-3c0-1.93,1.57-3.5,3.5-3.5h1c1.175,0,2.266,.585,2.917,1.564Z"
        fill={color}
      />
    </svg>
  );
};
