import React, { FC } from "react";
import { ButtonStyled } from "./ButtonStyled";
import { ColorName, Size } from "../../theme";

interface ButtonProps {
  color?: ColorName;
  background?: ColorName;
  size?: Size;
  transparent?: boolean;
  center?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  type?: string | any
}

export const Button: FC<ButtonProps> = (props) => {
  const { color, background, size, transparent, center, children, type, onClick } = props;
  return (
    <ButtonStyled
      background={background}
      center={center}
      type={type}
      color={color}
      size={size}
      transparent={transparent}
      onClick={onClick}
    >
      {children}
    </ButtonStyled>
  );
};
