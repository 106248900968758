import { API_URL } from "config";

export async function updateFacilityMonitoring(id: number, endpoint: string, payload: any) {
  const url = `${API_URL}/${endpoint}?id=eq.${id}`;
  const token = window.localStorage.getItem("accessToken");
  const response = await fetch(url, {
    method: "PATCH",
    headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    body: JSON.stringify(payload),
  });

  const text = await response.text();
  console.log("Server raw response:", text);
  if (!response.ok) {
    throw new Error(`Update ${endpoint} failed. Status: ${response.status}. Body: ${text}`);
  }
  let data: any;
  try {
    data = JSON.parse(text);
  } catch (parseErr) {
    console.warn("Failed to parse JSON. Returning raw text as data.");
    data = text;
  }
  return data;
}

export async function createFacilityMonitoring(endpoint: string, formData: unknown) {
  const url = `${API_URL}/${endpoint}`;
  const token = window.localStorage.getItem("accessToken");

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Prefer: "missing=default, return=representation",
    },
    body: JSON.stringify(formData),
  });

  const text = await response.text();
  console.log("Server raw response:", text);
  if (!response.ok) {
    throw new Error(`Create ${endpoint} failed. Status: ${response.status}. Body: ${text}`);
  }
  let data: any;
  try {
    data = JSON.parse(text);
  } catch (parseErr) {
    console.warn("Failed to parse JSON. Returning raw text as data.");
    data = text;
  }
  return data;
}

export async function deleteFacilityMonitoring(id: number, endpoint: string) {
  const url = `${API_URL}/${endpoint}?id=eq.${id}`;
  const token = window.localStorage.getItem("accessToken");
  const response = await fetch(url, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}` },
  });
  const text = await response.text();
  console.log("Server raw response:", text);
  if (!response.ok) {
    throw new Error(`Delete ${endpoint} failed. Status: ${response.status}. Body: ${text}`);
  }
  return text;
}
