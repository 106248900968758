import api from "api";
import { useEffect, useState, useRef, useCallback, useMemo } from "react";

import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import Select from "react-select";

import gql from "graphql-tag";

import { Title } from "ui/base/Title";
import store from "ui/redux/store";
import { Table } from "ui/base/Table";
import { Col } from "ui/blocks/WaterQuality/WaterQualityView/Col";

import { DownloadIcon, PrinterIcon } from "ui/icons";
import {
  WaterQualityViewStyled,
  Row,
  Text,
  IconWrapper,
} from "ui/blocks/WaterQuality/WaterQualityView/WaterQualityViewStyled";
import { ZipFileIcon } from "ui/icons/ZipFileIcon";

import { useQuery } from "@apollo/client";
import { WDID } from "mock/WDID";

import {
  sampleColumn,
  analyticalColumn,
  columnFirstSubheaders,
  tableStaticDate,
  footerStaticRaw,
  footerRaw,
} from "./constants/constants";

import { getSwapedKeysAndValues } from "../../../../helpers/functions";
import Message from "./Message/Message";

const DATE = gql`
  query GetReportYears($wdid: String!) {
    portal_qry_wq(where: { wdid: { _eq: $wdid } }) {
      report_year
    }
  }
`;

const WATER_QUALITY_QUERY = gql`
  query GetWaterQualityData($wdid: String!) {
    portal_qry_wq(where: { wdid: { _eq: $wdid } }) {
      collectiondate
      monitoringpoint
      parameter_smarts
      report_year
      result_display
      units
      nal_criteria_type
      nal_value
      nel_criteria_type
      nel_value
      annual_avg
    }
    portal_qry_facility_basic(where: { wdid: { _eq: $wdid } }) {
      facility_address
      facility_name
    }
  }
`;

type Data = {
  portal_qry_wq: DataItem[];
  portal_qry_facility_basic: Address[];
};

type DataItem = {
  collectiondate: string;
  monitoringpoint: string;
  parameter_smarts: string;
  report_year: string;
  result_display: string;
  units: string;
  nal_criteria_type: string;
  nal_value: string;
  nel_criteria_type: string;
  nel_value: string;
  annual_avg: string;
};

type NewItem = {
  collectiondate: string;
  monitoringpoint: string;
  report_year: string;
  params: NewItemParams[];
};

type NewItemParams = {
  parameter_smarts: string;
  result_display: string;
  units: string;
  annual_avg: string;
  number: number | null;
};

type Address = {
  facility_address: string;
  wdid: string;
  facility_name: string;
};

type NalVal = {
  parameter_smarts: string;
  nal_data_params: NalDataParams[];
};

type NalDataParams = {
  nal_criteria_type: string;
  nal_value: string;
};

type TMDLVal = {
  parameter_smarts: string;
  nel_data_params: TMDLDataParams[];
};

type TMDLDataParams = {
  nel_criteria_type: string;
  nel_value: string;
};

function getNalVal(data: Data): NalVal[] {
  let result: NalVal[] = [];

  data.portal_qry_wq.forEach((item: DataItem) => {
    let existingParam = result.find((res) => res.parameter_smarts === item.parameter_smarts);

    if (!existingParam) {
      existingParam = {
        parameter_smarts: item.parameter_smarts,
        nal_data_params: [],
      };
      result.push(existingParam);
    }

    if (!existingParam.nal_data_params.find((value) => item.nal_criteria_type === value.nal_criteria_type)) {
      existingParam.nal_data_params.push({
        nal_criteria_type: item.nal_criteria_type,
        nal_value: item.nal_value,
      });
    }
  });
  return result;
}

function getTMDLVal(data: Data): TMDLVal[] {
  let result: TMDLVal[] = [];

  data.portal_qry_wq.forEach((item: DataItem) => {
    let existingParam = result.find((res) => res.parameter_smarts === item.parameter_smarts);

    if (!existingParam) {
      existingParam = {
        parameter_smarts: item.parameter_smarts,
        nel_data_params: [],
      };
      result.push(existingParam);
    }

    if (!existingParam.nel_data_params.find((value) => item.nel_criteria_type === value.nel_criteria_type)) {
      existingParam.nel_data_params.push({
        nel_criteria_type: item.nel_criteria_type,
        nel_value: item.nel_value,
      });
    }
  });
  return result;
}

function getObj(data: Data): NewItem[] {
  let result: NewItem[] = [];
  let arr: string[] = [];

  data.portal_qry_wq.forEach((item: DataItem) => {
    if (!arr.includes(item.parameter_smarts)) {
      arr.push(item.parameter_smarts);
    }

    let existingItem = result.find(
      (res) => item.monitoringpoint === res.monitoringpoint && item.collectiondate === res.collectiondate
    );

    if (existingItem) {
      if (!existingItem.params.find((param) => param.parameter_smarts === item.parameter_smarts)) {
        existingItem.params.push({
          parameter_smarts: item.parameter_smarts,
          result_display: item.result_display,
          units: item.units,
          number: arr.indexOf(item.parameter_smarts),
          annual_avg: item.annual_avg,
        });
      }
    } else {
      result.push({
        monitoringpoint: item.monitoringpoint,
        report_year: item.report_year,
        collectiondate: item.collectiondate,
        params: [
          {
            parameter_smarts: item.parameter_smarts,
            result_display: item.result_display,
            annual_avg: item.annual_avg,
            units: item.units,
            number: arr.indexOf(item.parameter_smarts),
          },
        ],
      });
    }
  });
  return result;
}

function num(e: string) {
  if (isNaN(+e)) {
    return e;
  } else {
    const newNum = (+e).toFixed(4);
    let str = newNum.toString();
    for (let i = str.length - 1; i >= 0; i--) {
      if (str[i] === "0") {
        str = str.split("").slice(0, i).join("");
      } else break;
    }
    if (str[str.length - 1] === ".") {
      str = str.slice(0, -1);
    }
    return str;
  }
}

const WaterQualityView = () => {
  const [newWDID, setNewWDID] = useState(WDID);
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [selectedOutfalls, setSelectedOutfalls] = useState<string[]>([]);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<string | null>(null);

  const { loading, error, data } = useQuery<Data, { wdid: string }>(WATER_QUALITY_QUERY, {
    variables: {
      wdid: newWDID,
    },
    onCompleted: (data) => {
      console.log("Data fetched:", data);
    },
    onError: (err) => {
      console.error("Query error:", err);
    },
  });

  const {
    data: selectedDate,
    loading: loadingDate,
    error: errorDate,
  } = useQuery<Data, { wdid: string }>(DATE, {
    variables: { wdid: newWDID },
    skip: !newWDID,
  });

  const uniqueOutfalls: string[] = useMemo(() => {
    if (!data || selectedYears.length === 0) return [];
    return Array.from(
      new Set<string>(
        data.portal_qry_wq
          .filter((item) => selectedYears.includes(item.report_year))
          .map((item) => item.monitoringpoint)
      )
    );
  }, [data, selectedYears]);

  const sortedReportYears: string[] = useMemo(() => {
    if (!selectedDate) return [];
    return Array.from(new Set<string>(selectedDate.portal_qry_wq.map((item) => item.report_year)))
      .filter((n) => n)
      .sort((a, b) => b.localeCompare(a));
  }, [selectedDate]);

  useEffect(() => {
    if (!loadingDate && sortedReportYears.length > 0 && selectedYears.length === 0) {
      const initialYears = ["2024", "2025"].filter((year) => sortedReportYears.includes(year));
      if (initialYears.length === 0) {
        setSelectedYears(sortedReportYears.slice(0, 2));
      } else {
        setSelectedYears(initialYears);
      }
    }
  }, [loadingDate, sortedReportYears, selectedYears.length]);

  const filteredData: NewItem[] = useMemo(() => {
    if (!data) return [];
    let newObj = getObj(data);
    if (selectedYears.length > 0) {
      newObj = newObj.filter((item) => selectedYears.includes(item.report_year));
    }
    if (selectedOutfalls.length > 0) {
      newObj = newObj.filter((item) => selectedOutfalls.includes(item.monitoringpoint));
    }
    return newObj;
  }, [data, selectedYears, selectedOutfalls]);

  const [facilityNames, setFacilityNames] = useState<string>("");
  const [facilityAddress, setFacilityAddress] = useState<string>("");

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setNewWDID(store.getState().wdid);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleDownloadZIP = async () => {
    setIsDownloading(true);
    setDownloadError(null);

    const payload = {
      wdid: newWDID,
      years: selectedYears,
      locations: selectedOutfalls,
    };

    try {
      const response = await api.getArchiveLink("api/summary", payload);
      const downloadLink: string = response.url;

      if (downloadLink) {
        const zipResponse = await fetch(downloadLink, {
          method: "GET",
        });
        if (!zipResponse.ok) {
          throw new Error(`Can't download ZIP: ${zipResponse.statusText}`);
        }

        const blob = await zipResponse.blob();

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "summary.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error: any) {
      console.error("Download ZIP Error:", error);
      setDownloadError(error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  useEffect(() => {
    if (data) {
      console.log("Fetched Data:", data);
      const facility = data.portal_qry_facility_basic[0];
      if (facility) {
        setFacilityNames(facility.facility_name);
        setFacilityAddress(facility.facility_address);
      }
    }
  }, [data]);

  if (loading || loadingDate) return <Message>Loading...</Message>;
  if (error || errorDate) {
    console.error("GraphQL Error:", error || errorDate);
    return <Message>Facility has no data available</Message>;
  }

  if (filteredData.length === 0) {
    return <Message>Facility has no data available</Message>;
  }

  let nalVal = getNalVal(data!);
  let TMDLVal = getTMDLVal(data!);
  const tmdlNel = !!data!.portal_qry_wq.find((v: DataItem) => v.nel_criteria_type === "NEL");
  const tmdlNal = !!data!.portal_qry_wq.find((v: DataItem) => v.nel_criteria_type === "TNAL");

  let params: string[] = [];

  filteredData.forEach((item) => {
    item.params.forEach(({ parameter_smarts }) => {
      if (!params.includes(parameter_smarts)) {
        params.push(parameter_smarts);
      }
    });
  });

  params.sort((a?: string, b?: string) => {
    if (a && b) {
      const priority = ["pH", "Total Suspended Solids (TSS)", "Oil and Grease"];
      if (priority.includes(a) && !priority.includes(b)) return -1;
      if (!priority.includes(a) && priority.includes(b)) return 1;
      if (priority.indexOf(a) < priority.indexOf(b)) return -1;
      if (priority.indexOf(a) > priority.indexOf(b)) return 1;
      return a.localeCompare(b);
    }
    return 0;
  });

  const tableHeader = [];
  const firstColumnHeader: any = [];
  const secondColumnHeader: any = [];
  const firstColumnSubheader: any = {};
  const secondColumnSubheader: any = {};

  columnFirstSubheaders.forEach((subheader, index) => {
    if (index !== 0) {
      firstColumnHeader.push({ label: "", key: `${sampleColumn}${index}` });
    } else {
      firstColumnHeader.push({
        label: tableStaticDate.firstHeader,
        key: `${sampleColumn}${index}`,
      });
    }
  });

  params.forEach((param, index) => {
    if (index !== 0) {
      secondColumnHeader.push({
        label: "",
        key: `${analyticalColumn}${index}`,
      });
    } else {
      secondColumnHeader.push({
        label: tableStaticDate.secondHeader,
        key: `${analyticalColumn}${index}`,
      });
    }
  });

  columnFirstSubheaders.forEach((subheader, index) => {
    firstColumnSubheader[`${sampleColumn}${index}`] = subheader;
  });

  params.forEach((subheader, index) => {
    secondColumnSubheader[`${analyticalColumn}${index}`] = subheader;
  });

  tableHeader[0] = { ...firstColumnSubheader, ...secondColumnSubheader };
  const footerNavigationColumn = 0;

  const tableFooter: any = footerRaw.map((raw) => ({
    [`${sampleColumn}${footerNavigationColumn}`]: raw,
  }));

  const copyParams = { ...params };

  const convertTableDataToCSVFormat = (tableHeader: any, tableFooter: any) => {
    let CSVBodyData: any[] = [];

    filteredData.forEach(({ collectiondate, monitoringpoint, params }) => {
      let rawTable: any = {
        [`${sampleColumn}0`]: collectiondate,
        [`${sampleColumn}1`]: monitoringpoint,
      };
      params.forEach(({ parameter_smarts, result_display }: any) => {
        rawTable[`${analyticalColumn}${getSwapedKeysAndValues(copyParams)[parameter_smarts]}`] = result_display || "";
      });
      CSVBodyData.push(rawTable);
    });

    return [...tableHeader, ...CSVBodyData, ...tableFooter];
  };

  params.forEach((param, index) => {
    let res = "N/A";
    nalVal.forEach(({ parameter_smarts, nal_data_params }) => {
      if (param === parameter_smarts) {
        nal_data_params.forEach(({ nal_criteria_type, nal_value }) => {
          if (nal_criteria_type === footerStaticRaw.anual) {
            res = nal_value;
          }
        });
      }
    });
    if (!tableFooter[1][`${analyticalColumn}${index}`]) {
      tableFooter[1][`${analyticalColumn}${index}`] = res;
    }
  });

  params.forEach((param, index) => {
    let res = "N/A";
    nalVal.forEach(({ parameter_smarts, nal_data_params }) => {
      if (param === parameter_smarts) {
        nal_data_params.forEach(({ nal_criteria_type, nal_value }) => {
          if (nal_criteria_type === footerStaticRaw.minimum) {
            res = nal_value;
          }
        });
      }
    });
    if (!tableFooter[2][`${analyticalColumn}${index}`]) {
      tableFooter[2][`${analyticalColumn}${index}`] = res;
    }
  });

  params.forEach((param, index) => {
    let sum: number = 0;
    filteredData.forEach(({ params }) => {
      params.forEach(({ parameter_smarts, result_display }) => {
        if (param === parameter_smarts) {
          sum += +result_display || 0;
        }
      });
    });

    if (!tableFooter[0][`${analyticalColumn}${index}`]) {
      tableFooter[0][`${analyticalColumn}${index}`] =
        param === footerStaticRaw.avarage ? "N/A" : (sum / filteredData.length).toFixed(2);
    }
  });

  const firstColumnSubheaderKeys = columnFirstSubheaders.reduce((acc, subheader, index) => {
    acc[`${sampleColumn}${index}`] = subheader;
    return acc;
  }, {} as Record<string, string>);

  const secondColumnSubheaderKeys = params.reduce((acc, param, index) => {
    acc[`${analyticalColumn}${index}`] = param;
    return acc;
  }, {} as Record<string, string>);

  tableHeader[0] = { ...firstColumnSubheaderKeys, ...secondColumnSubheaderKeys };
  const csvHeaders = [...firstColumnHeader, ...secondColumnHeader];
  const csvData = convertTableDataToCSVFormat(tableHeader, tableFooter);

  return (
    <WaterQualityViewStyled>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Col width={100}>
          <Title position="left" size="h5">
            Water Quality Data Summary
          </Title>
        </Col>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            flexDirection: "row",
            justifyContent: "flex-end",
            flexWrap: "wrap",
          }}
        >
          <Col textAlign={"left"} display={"flex"}>
            <IconWrapper style={{ marginTop: "35px" }}>
              <PrinterIcon />
            </IconWrapper>
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle="WaterQualityData"
              removeAfterPrint
              trigger={() => (
                <button
                  style={{
                    borderRadius: 0,
                    background: "#eb7c32",
                    border: "1px solid transparent",
                    justifyContent: "center",
                    cursor: "pointer",
                    color: "#fff",
                    width: "150px",
                    padding: "11px",
                    fontSize: "14px",
                    lineHeight: 1,
                  }}
                >
                  Print Table
                </button>
              )}
            />
          </Col>

          <Col textAlign={"left"} display={"flex"}>
            <IconWrapper style={{ marginTop: "35px" }}>
              <ZipFileIcon />
            </IconWrapper>
            <button
              onClick={handleDownloadZIP}
              disabled={isDownloading}
              style={{
                fontSize: "14px",
                fontFamily: "Rajdhani",
                fontWeight: 600,
                borderRadius: 0,
                background: "#1c9eab",
                border: "1px solid transparent",
                justifyContent: "center",
                cursor: isDownloading ? "not-allowed" : "pointer",
                textAlign: "center",
                color: "#fff",
                width: "150px",
                margin: "0 0 0 0",
                padding: "11px",
                lineHeight: 1,
                opacity: isDownloading ? 0.6 : 1,
              }}
            >
              {isDownloading ? "Downloading..." : "Download ZIP"}
            </button>
          </Col>

          <Col textAlign={"left"} display={"flex"}>
            <IconWrapper style={{ marginTop: "35px" }}>
              <DownloadIcon />
            </IconWrapper>
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`WaterQualityReport_${selectedYears.length > 0 ? selectedYears.join("-") : "AllYears"}.csv`}
              style={{
                borderRadius: 0,
                background: "#1c6eac",
                border: "1px solid transparent",
                justifyContent: "center",
                cursor: "pointer",
                textAlign: "center",
                color: "#fff",
                width: "150px",
                margin: "0 0 0 0",
                padding: "11px",
                fontSize: "14px",
                lineHeight: 1,
              }}
            >
              Download Raw Data
            </CSVLink>
          </Col>
        </div>
      </div>

      <div style={{ width: "100%", marginBottom: "20px", display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <Select
          isMulti
          options={sortedReportYears.map((year) => ({ value: year, label: year }))}
          value={selectedYears.map((year) => ({ value: year, label: year }))}
          onChange={(selectedOptions: any) => {
            const years = selectedOptions.map((option: any) => option.value);
            setSelectedYears(years);
            setSelectedOutfalls([]);
          }}
          placeholder="Reporting Year(s)"
          styles={{
            control: (baseStyles: any, state: any) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "grey" : "darkblue",
              backgroundColor: state.isFocused ? "white" : "transparent",
            }),
            multiValue: (styles: any) => ({
              ...styles,
              backgroundColor: "darkBlue",
              color: "white",
            }),
            multiValueLabel: (styles: any) => ({
              ...styles,
              backgroundColor: "darkBlue",
              color: "white",
            }),
            multiValueRemove: (styles: any) => ({
              ...styles,
              backgroundColor: "darkBlue",
              color: "white",
              ":hover": {
                backgroundColor: "red",
                color: "white",
              },
            }),
          }}
        />
        {selectedYears.length > 0 && (
          <Select
            isMulti
            options={uniqueOutfalls.map((out) => ({ value: out, label: out }))}
            value={selectedOutfalls.map((out) => ({ value: out, label: out }))}
            onChange={(selectedOptions: any) => {
              const outfalls = selectedOptions.map((option: any) => option.value);
              setSelectedOutfalls(outfalls);
            }}
            placeholder="Sample Location(s)"
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "grey" : "darkBlue",
                backgroundColor: state.isFocused ? "white" : "transparent",
              }),
              multiValue: (styles: any) => ({
                ...styles,
                backgroundColor: "darkBlue",
                color: "white",
              }),
              multiValueLabel: (styles: any) => ({
                ...styles,
                backgroundColor: "darkBlue",
                color: "white",
              }),
              multiValueRemove: (styles: any) => ({
                ...styles,
                backgroundColor: "darkBlue",
                color: "white",
                ":hover": {
                  backgroundColor: "#0000ff50",
                  color: "white",
                },
              }),
            }}
          />
        )}
      </div>

      <div style={{ padding: "0 40px 40px", width: "100%" }} ref={componentRef}>
        <Row>
          <Text>
            {selectedYears.length > 0 ? selectedYears.join(", ") : "All Years"} Stormwater Sampling Results <br />{" "}
            {facilityNames} <br /> {facilityAddress} <br />
            WDID {newWDID}
          </Text>
          <Col width={100}>
            <Table
              widthCol={20}
              qtyCol={params.length + 2}
              header={
                <>
                  <tr>
                    <td align={"center"} colSpan={2}>
                      Sample Information
                    </td>
                    <td align={"center"} colSpan={params.length}>
                      Analytical Parameters
                    </td>
                  </tr>
                  <tr>
                    <td align={"center"}>Date</td>
                    <td align={"center"}>Sample Location</td>
                    {params.map((value) => (
                      <td align={"center"} key={value}>
                        {value}
                      </td>
                    ))}
                  </tr>
                </>
              }
            >
              <>
                {filteredData.map((data, index, array) => {
                  const previousItem = index > 0 ? array[index - 1] : null;
                  const showDate = !previousItem || previousItem.collectiondate !== data.collectiondate;
                  const rowSpan = array.filter(({ collectiondate }) => collectiondate === data.collectiondate).length;

                  return (
                    <tr key={`${data.collectiondate}-${data.monitoringpoint}-${index}`}>
                      {showDate && (
                        <td align={"center"} rowSpan={rowSpan}>
                          {data.collectiondate}
                        </td>
                      )}
                      <td align={"center"}>{data.monitoringpoint}</td>

                      {params.map((param) => {
                        const paramData = data.params.find((p) => p.parameter_smarts === param);
                        return (
                          <td align={"center"} key={param}>
                            {paramData ? `${num(paramData.result_display)} ${paramData.units}` : ""}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2} align={"right"}>
                    Average
                  </td>
                  {params.map((param) => {
                    let sum: number = 0;
                    let count: number = 0;
                    let unit: string = "";
                    filteredData.forEach(({ params }) => {
                      params.forEach(({ parameter_smarts, units, annual_avg }) => {
                        if (param === parameter_smarts) {
                          unit = units;
                          if (!isNaN(Number(annual_avg))) {
                            sum += Number(annual_avg);
                            count++;
                          }
                        }
                      });
                    });
                    const average = count > 0 ? (sum / count).toFixed(2) : "N/A";
                    return (
                      <td align={"center"} key={`avg-${param}`}>
                        {param === "pH" ? "N/A" : `${num(average)} ${unit}`}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td colSpan={2} align={"right"}>
                    Annual NAL (Instantaneous NAL)
                  </td>
                  {params.map((param) => {
                    let res = "N/A";
                    nalVal.forEach(({ parameter_smarts, nal_data_params }) => {
                      if (param === parameter_smarts) {
                        nal_data_params.forEach(({ nal_criteria_type, nal_value }) => {
                          if (nal_criteria_type === "Annual NAL (Instantaneous NAL)") {
                            res = nal_value;
                          }
                        });
                      }
                    });
                    return (
                      <td align={"center"} key={`annual_nal-${param}`}>
                        {num(res)}
                      </td>
                    );
                  })}
                </tr>
                {tmdlNel && (
                  <tr>
                    <td colSpan={2} align={"right"}>
                      TMDL NELs
                    </td>
                    {params.map((param) => {
                      let res = "N/A";
                      TMDLVal.forEach(({ parameter_smarts, nel_data_params }) => {
                        if (param === parameter_smarts) {
                          nel_data_params.forEach(({ nel_criteria_type, nel_value }) => {
                            if (nel_criteria_type === "NEL") {
                              res = nel_value;
                            }
                          });
                        }
                      });
                      return (
                        <td align={"center"} key={`tmdl_nel-${param}`}>
                          {num(res)}
                        </td>
                      );
                    })}
                  </tr>
                )}
                {tmdlNal && (
                  <tr>
                    <td colSpan={2} align={"right"}>
                      TMDL NALs
                    </td>
                    {params.map((param) => {
                      let res = "N/A";
                      TMDLVal.forEach(({ parameter_smarts, nel_data_params }) => {
                        if (param === parameter_smarts) {
                          nel_data_params.forEach(({ nel_criteria_type, nel_value }) => {
                            if (nel_criteria_type === "TNAL") {
                              res = nel_value;
                            }
                          });
                        }
                      });
                      return (
                        <td align={"center"} key={`tmdl_nal-${param}`}>
                          {num(res)}
                        </td>
                      );
                    })}
                  </tr>
                )}
              </>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col width={100}>
            <p>
              <h4
                style={{
                  fontSize: "20px",
                  marginTop: "20px",
                  color: "#eb7c32",
                }}
              >
                Notes:
              </h4>
              <br />
              DNQ = Detected not quantifiable. A value of 0 is used to calculate the average.
              <br />
              mg/L = milligrams per liter.
              <br />
              N/A = Not applicable.
              <br />
              NA = Not Analyzed.
              <br />
              NAL = Numeric Action Level.
              <br />
              ND = Non-detect values. A value of 0 is used to calculate the average.
              <br />
              s.u. = Standard pH Units.
              <br />
              NEL = Numeric Effluent Limit. Values in bold, red text are above the NEL Value.
              <br />
              <br />
              Reference:
              <br />
              1. NAL values per NPDES Industrial General Permit, Order 2014-0057-DWQ, Table 2, Section XI.B.
              <br />
              2. NEL values per Industrial General Permit, Order 2014-0057-DWQ as amended by Order 2015-0122-DWQ,
              Attachment E, Table 2.
              <br />
            </p>
          </Col>
        </Row>
      </div>
    </WaterQualityViewStyled>
  );
};

export default WaterQualityView;
