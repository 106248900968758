interface IconProps {
  color?: string;
}

export const SiteMap: React.FC<IconProps> = ({ color = "darkBlue" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
      <path
        d="m12,7.006c1.103,0,2-.897,2-2s-.897-2-2-2-2,.897-2,2,.897,2,2,2Zm0-3c.551,0,1,.448,1,1s-.449,1-1,1-1-.448-1-1,.449-1,1-1Zm0,7.81l3.535-3.28c.944-.943,1.465-2.199,1.465-3.535s-.52-2.592-1.465-3.535c-.944-.944-2.2-1.465-3.535-1.465s-2.591.521-3.535,1.465c-.944.943-1.465,2.199-1.465,3.535s.524,2.596,1.489,3.559l3.511,3.257Zm-2.828-9.644c.755-.756,1.76-1.172,2.828-1.172s2.073.416,2.828,1.172c.756.755,1.172,1.759,1.172,2.828s-.416,2.073-1.159,2.815l-2.841,2.637-2.817-2.613c-.763-.762-1.183-1.77-1.183-2.839s.416-2.073,1.172-2.828Zm14.82,20.738l-2.701-14.857c-.211-1.16-1.201-2.003-2.371-2.045-.048.337-.114.669-.21.992h.121c.725,0,1.346.519,1.476,1.232l.716,3.929-10.722,4.662-7.026-6.299.417-2.292c.13-.714.751-1.232,1.476-1.232h.126c-.097-.324-.165-.656-.214-.992-1.17.041-2.16.885-2.372,2.045L0,23v1h24l-.008-1.09ZM3.066,11.679l12.627,11.321H1.008l2.058-11.321Zm14.125,11.321l-6.07-5.442,10.087-4.385,1.792,9.827h-5.809Z"
        fill={color}
      />
    </svg>
  );
};
