import api from "api";
import { useEffect, useState } from "react";

import StormWaterInspectionForm from "ui/blocks/monitoring/Forms/MonthlyVisualObservationForm";
import { initialFormData, InspectionFormData } from "ui/blocks/monitoring/types";


export default function MounthlyObservationForm() {
  const queryParams = new URLSearchParams(window.location.search);

  const id = queryParams.get("id");
  const mode = queryParams.get("mode");
  const wdid = queryParams.get("wdid");
  const year = queryParams.get("year");

  const [data, setData] = useState<InspectionFormData>(initialFormData);

  useEffect(() => {
    if (!id) return;
    api.get(`facility_monitoring?id=eq.${id}`).then((res) => {
      if (res.data && res.data.length > 0) {
        setData(res.data[0]);
      }
    });
  }, [id]);

  if (!data) return <div>Loading...</div>;

  return <StormWaterInspectionForm wdid={wdid} year={year} formData={data} isReadOnly={mode === "view"} />;
};

