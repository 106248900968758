import api from "api";
import { useEffect, useState } from "react";
import AnnualFacilityForm from "ui/blocks/monitoring/Forms/AnnualFacilityForm";
import { AnnualFacilityDataProps, initialAnnualFacilityData } from "ui/blocks/monitoring/types";

export default function AnnualFacilityFormPage() {
  const queryParams = new URLSearchParams(window.location.search);

  const id = queryParams.get("id");
  const mode = queryParams.get("mode");
  const wdid = queryParams.get("wdid");

  const [annualFacilityFormPageData, setAnnualFacilityFormPageData] =
    useState<AnnualFacilityDataProps>(initialAnnualFacilityData);

  useEffect(() => {
    if (!id) return;
    api.get(`annual_facility?id=eq.${id}`).then((res) => {
      if (res.data && res.data.length > 0) {
        setAnnualFacilityFormPageData(res.data[0]);
      }
    });
  }, [id]);

  if (!annualFacilityFormPageData) return <div>Loading...</div>;

  return <AnnualFacilityForm wdid={wdid} formData={annualFacilityFormPageData} isReadOnly={mode === "view"} />;
}
