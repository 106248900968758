import styled from "styled-components";
import { themeColor } from "ui/theme";

export const DocumentsFormStyled = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const DocumentFormLinkStyled = styled.a`
  width: fit-content;
  flex-wrap: wrap;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

export const DocumentFormButtonStyled = styled.button`
  width: fit-content;
  color: #366C9E;
  cursor: pointer;
  border: 1px solid #4378B4;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: #4378B4;
    color: #eb7c32;
  }
`;
