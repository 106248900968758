import { toastr } from "react-redux-toastr";

import { getToken } from "config/getToken";
import { API_URL, MEDIA_STORAGE_URI, REACT_APP_ARCHIVE_SERVICE_URL} from "../config";

const success = (results: any, fetchObject: any) => {
  if (results.status === 403 || results.status === 401) {
    const hard = results.status === 401;
    return getToken(hard).then((token) => {
      fetchObject.headers.set("authorization", "Bearer " + token);
      return fetch(results.url, fetchObject).then((results) => {
        if (results.status !== 200 && results.status !== 201 && results.status !== 302) {
          toastr.error("Error " + results.status, results.statusText);
        }
        return results.json();
      });
    });
  } else {
    if (results.status !== 200 && results.status !== 201 && results.status !== 302) {
      toastr.error("Error " + results.status, results.statusText);
    }
    return results.json();
  }
};

const returnData = (data: any) => {
  let error: any = { occur: false };
  if (typeof data === "object" && data !== null && data.code) {
    toastr.error("Error Details:", data.message);
    error.occur = true;
    error.message = data.message;
  }
  return { error, data: error.occur ? [] : data };
};

const getWithoutToken = (url: string) => {
  let fetchObject = {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  };

  return fetch(API_URL + "/" + url, fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};

const createWithoutToken = (url: string, data: any) => {
  let fetchObject = {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({
      "Content-Type": "application/json",
      prefer: "return=representation",
    }),
  };

  return fetch(API_URL + "/" + url, fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};

const getArchiveLink = async (url: string, data: any) => {
  const fullUrl = url.startsWith("http")
    ? url
    : `${REACT_APP_ARCHIVE_SERVICE_URL}/${url.replace(/^\/+/, "")}`;

  try {
    const response = await fetch(fullUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        prefer: "return=representation",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || JSON.stringify(errorData));
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw error;
  }
};

const get = (url: string, addition?: any) => {
  let token = window.localStorage.getItem("accessToken");
  let fetchObject = {
    method: "GET",
    headers: new Headers({
      authorization: "Bearer " + token,
      "Content-Type": "application/json",
    }),
  };

  let requestString = API_URL + "/" + url;
  if (addition && addition.length) requestString = requestString + addition;

  return fetch(requestString, fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};

const getSome: CallableFunction = (url: string, search: any, addition?: any, headers?: any) => {
  let token = window.localStorage.getItem("accessToken");
  let fetchObject = {
    method: "GET",
    headers: new Headers({
      ...headers,
      authorization: "Bearer " + token,
      "Content-Type": "application/json",
    }),
  };

  let requestString = API_URL + "/" + url + search;
  if (addition && addition.length) requestString = requestString + addition;

  return fetch(requestString, fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};

const getOne: CallableFunction = (url: string, id: any, addition: any) => {
  let token = window.localStorage.getItem("accessToken");
  let fetchObject = {
    method: "GET",
    headers: new Headers({
      authorization: "Bearer " + token,
      "Content-Type": "application/json",
    }),
  };

  let requestString = API_URL + "/" + url + "?id=eq." + id;
  if (addition && addition.length) requestString = requestString + addition;

  return fetch(requestString, fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};
const addOne = (data: any) => {
  let token = window.localStorage.getItem("accessToken");
  let fetchObject = {
    method: "POST",
    body: data,
    headers: new Headers({
      Authorization: "Bearer " + token,
    }),
  };

  return fetch(MEDIA_STORAGE_URI + "/local/upload", fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};

const edit = (url: string, id: any, data: any) => {
  let token = window.localStorage.getItem("accessToken");
  let fetchObject = {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: new Headers({
      authorization: "Bearer " + token,
      "Content-Type": "application/json",
      prefer: "return=representation",
    }),
  };

  return fetch(API_URL + "/" + url + "?id=eq." + id, fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};

const create = (url: string, data: any) => {
  let token = window.localStorage.getItem("accessToken");
  let fetchObject = {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({
      authorization: "Bearer " + token,
      "Content-Type": "application/json",
      prefer: "return=representation",
    }),
  };

  return fetch(API_URL + "/" + url, fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};

const deleteOne = (url: string, search: any) => {
  let token = window.localStorage.getItem("accessToken");
  let fetchObject = {
    method: "DELETE",
    headers: new Headers({
      authorization: "Bearer " + token,
      "Content-Type": "application/json",
      prefer: "return=representation",
    }),
  };

  return fetch(API_URL + "/" + url + search, fetchObject)
    .then((results) => {
      return success(results, fetchObject);
    })
    .then((data) => {
      return returnData(data);
    });
};

export default {
  getWithoutToken: getWithoutToken,
  createWithoutToken: createWithoutToken,
  getArchiveLink: getArchiveLink,
  get: get,
  getSome: getSome,
  getOne: getOne,
  edit: edit,
  create: create,
  delete: deleteOne,
  addOne: addOne,
};
