import api from "api";
import { useEffect, useState } from "react";

import store from "ui/redux/store";
import { WDID } from "mock/WDID";
import { MonitotingViewTable, Text } from "ui/blocks/monitoring/MonitoringView/MonitoringViewStyled";
import {
  SelectOptions,
  Wrapper,
  Btn,
  Container,
  OptionsList,
  Option,
} from "ui/blocks/WaterQuality/WaterQualityView/WaterQualityViewStyled";
import { Table } from "ui/base/Table";
import { Button } from "ui/base/Button";
import { DownArrow } from "ui/icons";

type Data = {
  wdid: string;
  id: number;
  inspection_date: string;
  reporting_year: string;
  sample_date: string;
  status: string;
  formUploaded: boolean;
};

const AnnualFacilityTable = () => {
  const [annualFacilityData, setAnnualFacilityData] = useState<Data[]>([]);
  const [selectedYearRange, setSelectedYearRange] = useState<string>("All");
  const [filteredRows, setFilteredRows] = useState<Data[]>([]);
  const [newWDID, setNewWDID] = useState<string>(WDID);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setNewWDID(store.getState().wdid);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.get("annual_facility");
        const updatedData = result.data.map((row: Data) => ({
          ...row,
          status: calculateStatus(row),
        }));
        setAnnualFacilityData(updatedData);
        setFilteredRows(updatedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, []);

  const calculateCompleteByDate = (reportingYear: string) => {
    const endYear = parseInt(reportingYear.split("-")[0]) + 1;
    return new Date(`June 30, ${endYear}`);
  };

  const calculateStatus = (row: Data) => {
    const completeByDate = calculateCompleteByDate(row.reporting_year);
    const currentDate = new Date();

    if (row.formUploaded) {
      return "Complete";
    } else if (currentDate < completeByDate) {
      return "Future";
    }
    return row.status;
  };

  const uniqueYearRanges = Array.from(
    new Set(
      annualFacilityData.map(
        ({ reporting_year }) => `${reporting_year.split("-")[0]}-${parseInt(reporting_year.split("-")[0]) + 1}`
      )
    )
  ).sort();

  useEffect(() => {
    let filtered = annualFacilityData;
    if (selectedYearRange !== "All") {
      const [startYear, endYear] = selectedYearRange.split("-").map(Number);
      filtered = filtered.filter(({ reporting_year }) => {
        const parsedYear = parseInt(reporting_year.split("-")[0], 10);
        return parsedYear >= startYear && parsedYear < endYear;
      });
    }
    filtered = filtered.filter(({ wdid }) => wdid === newWDID);
    setFilteredRows(filtered);
  }, [selectedYearRange, annualFacilityData, newWDID]);

  const toggleOptions = () => setIsOptionsOpen(!isOptionsOpen);

  const setSelectedThenCloseDropdown = (index: number) => {
    setSelectedYearRange(uniqueYearRanges[index]);
    setIsOptionsOpen(false);
  };

  return (
    <MonitotingViewTable style={{ position: "relative" }}>
      <Text>ANNUAL COMPREHENSIVE FACILITY COMPLIANCE EVALUATION FORM</Text>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "20px" }}>
        <SelectOptions>
          <Wrapper>
            <Container>
              <Btn type="button" onClick={toggleOptions}>
                <span>{selectedYearRange === "All" ? "Select year range" : `${selectedYearRange}`}</span>
                <div
                  style={{
                    width: "20px",
                    position: "absolute",
                    right: "5px",
                    top: "9px",
                    transform: `${isOptionsOpen ? "rotate(180deg)" : "rotate(0deg)"}`,
                  }}
                >
                  <DownArrow />
                </div>
              </Btn>
              <OptionsList isOpen={isOptionsOpen}>
                {uniqueYearRanges.map((option, index) => (
                  <Option
                    key={option}
                    id={option}
                    aria-selected={selectedYearRange === option}
                    onClick={() => setSelectedThenCloseDropdown(index)}
                  >
                    {option}
                  </Option>
                ))}
              </OptionsList>
            </Container>
          </Wrapper>
        </SelectOptions>

        <Button color="white" background="darkBlue">
          Download blank form
        </Button>

        <Button color="white">
          <a href={`/annual-facility-form?wdid=${newWDID}`}>Add</a>
        </Button>
      </div>

      <Table
        header={
          <tr>
            <th>Reporting Year</th>
            <th>Complete by</th>
            <th>Status</th>
            <th colSpan={3}>Form</th>
          </tr>
        }
      >
        {filteredRows.map((row) => (
          <tr key={row.id}>
            <td style={{ textAlign: "center" }}>{`${row.reporting_year.split("-")[0]}-${
              parseInt(row.reporting_year.split("-")[0]) + 1
            }`}</td>
            <td style={{ textAlign: "center" }}>{calculateCompleteByDate(row.reporting_year).toLocaleDateString()}</td>
            <td style={{ textAlign: "center" }}>{calculateStatus(row)}</td>
            <td>
              <a href={`/annual-facility-form?id=${row.id}&mode=view&wdid=${row.wdid}`}>View</a>
            </td>
            <td>
              <a href={`/annual-facility-form?id=${row.id}&mode=edit&wdid=${row.wdid}`}>Start/Edit</a>
            </td>
            <td>
              <a
                href={`/annual-facility-form-update?id=${row.id}&year=${
                  row.reporting_year
                }&complete_by=${calculateCompleteByDate(row.reporting_year).toLocaleDateString()}&wdid=${newWDID}`}
              >
                Upload
              </a>
            </td>
          </tr>
        ))}
      </Table>
    </MonitotingViewTable>
  );
};

export default AnnualFacilityTable;
