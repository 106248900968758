import { MonitoringRow, MonitoringViewStyled } from "./MonitoringViewStyled";
import { AnnualFacilityTable } from "../AnnualFacility";
import { SampleObservationTable } from "../SampleObservation";

import MontlyObservationTable from "../MonthlyObservation/MonthlyObservation";
import { StormwaterMonitoringCol } from "ui/blocks/portal/StormwaterMonitoringCol/";
import { Title } from "ui/base/Title";
import LeaderInspection from "ui/blocks/monitoring/LeaderInspection/LeaderInspection";

const MonitoringView = () => {
  return (
    <MonitoringViewStyled>
      <MonitoringRow>
        <StormwaterMonitoringCol background={"inherit"} />
        <LeaderInspection />
      </MonitoringRow>
      <Title position="left" uppercase weight="600" size="h5">
        Facility monitoring groups
      </Title>
      <MonitoringRow>
        <MontlyObservationTable />
        <SampleObservationTable />
        <AnnualFacilityTable />
      </MonitoringRow>
    </MonitoringViewStyled>
  );
};

export default MonitoringView;
