import { ModalWarningContainer, ModalWarningForm, ModalWarningH1Text, ModalWarningButton } from "./ModalWarningStyled";

interface ModalWarningProps {
  isOpen: boolean;
  onConfirm: (confirmed: boolean) => void;
  onCancel: () => void;
}

const ModalWarning = ({ isOpen, onConfirm, onCancel }: ModalWarningProps) => {
  if (!isOpen) return null;

  return (
    <ModalWarningContainer isOpen={isOpen}>
      <ModalWarningForm>
        <ModalWarningH1Text>Are you sure you want to delete?</ModalWarningH1Text>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", margin: "20px 0 0 0", justifyContent: "space-between" }}>
          <ModalWarningButton onClick={() => onConfirm(true)} type="button" buttonType={"agree"}>
            Yes
          </ModalWarningButton>
          <ModalWarningButton onClick={() => onCancel()} type="button" buttonType={"disagree"}>
            No
          </ModalWarningButton>
        </div>
      </ModalWarningForm>
    </ModalWarningContainer>
  );
};

export default ModalWarning;
